import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  calcularParcelas,
  criarTokenPagarme,
  obterResumoPorPropostaId,
  PagamentoBoletoResponse,
  PagamentoResumoResponse,
  Parcelamento,
  realizarPagamentoBoleto,
  realizarPagamentoCartaoCredito,
  realizarPagamentoPix,
  salvarDadosPessoaisPagador
} from 'src/api/pagamento/index'

import { ICartaoForm } from 'src/components/pagamento/cartaoCredito'
import { IDadosPessoais } from 'src/components/pagamento/formDadosPessoais'
import { gerarGuid, onlyNumber } from 'src/core/helpers/common'
import {
  showNotification,
  showNotificationErrorAPI
} from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import PagamentoContext, {
  IBoletoRequest,
  IPixRequest,
  T_FORMAS_PAGAMENTO
} from './context'
import { SynRoutes } from 'src/core/consts/synRoutes'

const PagamentoProvider = ({ children }) => {
  const history = useHistory()
  const { user, signed, perfilAtual } = useAuth()

  const [loading, setLoading] = useState(true)
  const [loadingResumo, setLoadingResumo] = useState(true)

  const [loadingPagamento, setLoadingPagamento] = useState(false)
  const [pagamentoCreditoFracionado, setPagamentoCreditoFracionado] = useState(
    false
  )

  const [existePagamentoFracionado, setExistePagamentoFracionado] = useState(
    false
  )

  const [loadingPix, setLoadingPix] = useState(false)
  const [loadingBoleto, setLoadingBoleto] = useState(false)
  const [loadingCartao, setLoadingCartao] = useState(false)

  const [loadingParcelas, setLoadingParcelas] = useState(false)

  const [urlPix, setUrlPix] = useState<string>(null)
  const [pixCopiaCola, setPixCopiaCola] = useState<string>(null)
  const [urlPixFracionado, setUrlPixFracionado] = useState<string>(null)
  const [pixCopiaColaFracionado, setPixCopiaColaFracionado] = useState<string>(
    null
  )

  const [boleto, setBoleto] = useState<PagamentoBoletoResponse>(null)
  const [
    boletoFracionado,
    setBoletoFracionado
  ] = useState<PagamentoBoletoResponse>(null)

  const [resumo, setResumo] = useState<PagamentoResumoResponse>(null)
  const [dadosPessoais, setDadosPessoais] = useState<IDadosPessoais>(null)

  const [parcelamento, setParcelamento] = useState<Parcelamento>(null)
  // const [formaPagamento, setFormaPagamento] = useState<T_FORMAS_PAGAMENTO>(
  //   'multiplas'
  // )
  const [formaPagamento, setFormaPagamento] = useState<T_FORMAS_PAGAMENTO>(null)

  const [propostaId, setPropostaId] = useState<string>(null)

  async function obterResumoPagamento(propostaId: string, showLoading = true) {
    try {
      setLoadingResumo(true)
      setPropostaId(propostaId)
      const { data: resumo } = await obterResumoPorPropostaId(propostaId)
      if (resumo.saldoDevedor < resumo.valorTotal) {
        selecionarFormaPagamento('multiplas', false)
        setExistePagamentoFracionado(true)
      }
      setResumo(resumo)

      setDadosPessoais(resumo?.dadosPessoais)
      setLoadingResumo(false)
      setLoading(false)
      return true
    } catch (error) {
      showNotificationErrorAPI(error)
      history.goBack()
      setLoadingResumo(false)
      setLoading(false)
      return false
    }
  }

  async function obterParcelas(valor: number) {
    try {
      setLoadingParcelas(true)
      setPropostaId(propostaId)
      const { data: parcelamento } = await calcularParcelas(valor)

      setParcelamento(parcelamento)
      setLoadingParcelas(false)
      return true
    } catch (error) {
      showNotificationErrorAPI(error)
      return false
    }
  }

  function selecionarFormaPagamento(forma: T_FORMAS_PAGAMENTO, limpar = true) {
    if (limpar) limparFracionado()
    setFormaPagamento(forma)
  }

  async function pagamentoPix(request: IPixRequest) {
    try {
      if (request.integral && !request.valor) {
        showNotification('Valor é obrigatório pix', '', 'warning')
        return
      }

      setLoadingPix(true)
      const { data } = await realizarPagamentoPix({
        propostaId: propostaId,
        integral: request.integral,
        valor: request.integral ? null : request.valor,

        cidade: dadosPessoais.cidade,
        cep: onlyNumber(dadosPessoais.cep),
        endereco: dadosPessoais.endereco,
        estado: dadosPessoais.estado,

        documento: onlyNumber(dadosPessoais.documento),
        email: dadosPessoais.email,
        nome: dadosPessoais.nome,
        telefone: onlyNumber(dadosPessoais.telefone)
      })
      obterResumoPagamento(propostaId)

      if (request.integral) {
        setUrlPix(data?.urlPix)
        setPixCopiaCola(data?.codigoPix)
      } else {
        setUrlPixFracionado(data?.urlPix)
        setPixCopiaColaFracionado(data?.codigoPix)
      }

      setLoadingPix(false)
    } catch (error) {
      showNotificationErrorAPI(error)
      setLoadingPix(false)
    }
  }

  async function pagamentoBoleto(request: IBoletoRequest) {
    try {
      if (request.integral && !request.valor) {
        showNotification('Valor é obrigatório boleto', '', 'warning')
        return
      }
      setLoadingBoleto(true)

      const { data } = await realizarPagamentoBoleto({
        propostaId: propostaId,
        integral: request.integral,
        valor: request.valor,
        cidade: dadosPessoais.cidade,
        cep: onlyNumber(dadosPessoais.cep),
        endereco: dadosPessoais.endereco,
        estado: dadosPessoais.estado,

        documento: onlyNumber(dadosPessoais.documento),
        email: dadosPessoais.email,
        nome: dadosPessoais.nome,
        telefone: onlyNumber(dadosPessoais.telefone)
      })

      obterResumoPagamento(propostaId)
      if (request.integral) {
        setBoleto(data)
      } else {
        setBoletoFracionado(data)
      }

      setLoadingBoleto(false)
    } catch (error) {
      showNotificationErrorAPI(error)
      setLoadingBoleto(false)
    }
  }

  function limparFracionado() {
    setBoletoFracionado(null)
    setUrlPixFracionado(null)
    setPagamentoCreditoFracionado(false)
    setLoadingBoleto(false)
    setLoadingPix(false)
    setLoadingCartao(false)

    if (!(resumo?.saldoDevedor < resumo?.valorTotal)) {
      setExistePagamentoFracionado(false)
    }
  }

  function copiarNumeroBoleto(boletoResponse?: PagamentoBoletoResponse) {
    const codigoBarra = !!boletoResponse ? boletoResponse.codigoBarra : boleto

    copyTextToClipboard(codigoBarra)
  }

  async function salvarDadosPessoais(form: IDadosPessoais) {
    try {
      form.propostaId = propostaId

      const { data } = await salvarDadosPessoaisPagador(form)

      setDadosPessoais(form)
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  const showRoutePayment = (propostaId: string) => {
    setTimeout(() => {
      history.push(`${SynRoutes.acompanharPagamento}?p=${propostaId}`)
    }, 2000)
  }

  async function pagamentoCartaoCredito(form: ICartaoForm) {
    try {
      setLoadingCartao(true)
      console.log(form)
      const validadeMes = parseInt(form.expiry.substring(0, 2))
      const validadeAno = parseInt(form.expiry.substring(3)) + 2000
      await criarTokenPagarme({
        cvv: form.cvc,
        expMonth: validadeMes,
        expYear: validadeAno,
        holderDocument: onlyNumber(dadosPessoais.documento),
        holderName: form.nome,
        number: onlyNumber(form.number)
      })
        .then(async ({ data: tokenResponse }) => {
          const { data } = await realizarPagamentoCartaoCredito({
            propostaId: propostaId,
            tokenCartao: tokenResponse.id,
            numeroParcela: form.numeroParcela,
            integral: form.integral,
            cidade: dadosPessoais.cidade,
            cep: onlyNumber(dadosPessoais.cep),
            endereco: dadosPessoais.endereco,
            estado: dadosPessoais.estado,
            valor: form.valor,

            documento: onlyNumber(dadosPessoais.documento),
            email: dadosPessoais.email,
            nome: dadosPessoais.nome,
            telefone: onlyNumber(dadosPessoais.telefone)
          })
          if (data?.fluxoAntifraude === true) {
            showNotification(
              'O pagamento está sendo analisado.',
              'A análise pode levar até 48h e você será notificado após o processamento.',
              'warning'
            )
            showRoutePayment(propostaId)
            setLoadingCartao(false)
          } else {
            setPagamentoCreditoFracionado(true)
            obterResumoPagamento(propostaId)

            showNotification(
              'Pagamento realizado com sucesso',
              'Em breve entraremos em contato para agendamento',
              'success'
            )
            setLoadingCartao(false)
          }
        })
        .catch(error => {
          showNotificationErrorAPI(error)
          setLoadingCartao(false)
        })
    } catch (error) {
      showNotificationErrorAPI(error)
      setLoadingCartao(false)
    }
  }

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      var successful = document.execCommand('copy')
      var msg = successful ? 'successful' : 'unsuccessful'
      console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!')
      },
      function (err) {
        console.error('Async: Could not copy text: ', err)
      }
    )
  }

  function reiniciarContext() {
    setLoading(true)
    setLoadingResumo(true)
    setLoadingPagamento(false)
    setLoadingBoleto(false)
    setLoadingPix(false)
    setLoadingCartao(false)

    setExistePagamentoFracionado(false)

    setResumo(null)
    setFormaPagamento(null)

    setPropostaId(null)

    setBoleto(null)
    setBoletoFracionado(null)

    setUrlPix(null)
    setUrlPixFracionado(null)

    setPagamentoCreditoFracionado(false)
  }
  useEffect(() => {}, [])

  return (
    <PagamentoContext.Provider
      value={{
        propostaId,
        loading,
        loadingPagamento,
        loadingBoleto,
        loadingPix,
        loadingCartao,
        loadingResumo,
        existePagamentoFracionado,
        urlPix,
        urlPixFracionado,
        pixCopiaCola,
        pixCopiaColaFracionado,
        resumo,
        boleto,
        boletoFracionado,
        formaPagamento,
        pagamentoCreditoFracionado,
        parcelamento,
        loadingParcelas,
        dadosPessoais,
        pagamentoCartaoCredito,
        pagamentoBoleto,
        pagamentoPix,
        obterResumoPagamento,
        selecionarFormaPagamento,
        copiarNumeroBoleto,
        copyTextToClipboard,
        limparFracionado,
        reiniciarContext,
        obterParcelas,
        salvarDadosPessoais
      }}
    >
      {children}
    </PagamentoContext.Provider>
  )
}

export default PagamentoProvider
