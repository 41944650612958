import React, { useEffect, useState } from 'react'
import {
  Box,
  makeStyles,
  IconButton,
  Typography,
  Grid,
  Card,
  Divider,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import Button from 'src/components/ui/Button'
import { ResumoPropostaResponse } from 'src/types'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { TextField } from 'src/components/ui/TextField'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from 'src/components/yupCustom'
import { Alert } from '@material-ui/lab'
import StatusProposta from 'src/core/consts/statusProposta'
import { useHistory } from 'react-router-dom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import UBox from '../ui/Ubox'
import { primeiraLetraMaiusculo } from 'src/core/helpers/common'
import { FiInfo } from 'react-icons/fi'

const btnRed = {
  border: '1px solid red',
  color: 'red'
}

const contestacaoSchema = yup.object().shape({
  contestacao: yup.string().required().min(4).label('Contestação')
})

const useStyles = makeStyles(theme => ({
  container: {
    // background: theme.palette.background.paper
  },
  OPMECard: {
    padding: '20px',
    marginBottom: '20px',
    boxShadow: 'none',
    border: '1px solid #efefef',
    background: theme.palette.background.paper
  },
  OPMECardItem: {
    margin: '5px 0'
  },
  hospitalCard: {
    color: '#465a81',
    padding: '20px',
    textAlign: 'center',
    border: '1px solid #465b82',
    margin: '20px 0'
  },
  hositalCardText: {
    margin: '1px 0'
  },
  cardDivider: {
    margin: '10px 0'
  },
  motivoCard: {
    border: '1px solid #efefef',
    background: theme.palette.background.paper,
    padding: '10px'
  },
  aprovadoPacienteAlert: {
    backgroundColor: '#8d44ad39',
    color: '#8e44ad',
    '& .MuiAlert-icon': {
      color: '#8e44ad'
    }
  },
  aguardandoPacienteAlert: {
    backgroundColor: '#E67F2439',
    color: '#c26b20',
    '& .MuiAlert-icon': {
      color: '#c26b20'
    }
  },
  propostaVencedoraAlert: {
    backgroundColor: '#009431',
    color: '#fff',
    '& .MuiAlert-icon': {
      color: '#fff'
    }
  },
  boxAlert: {
    '& .MuiAlert-message': {
      width: '100%'
    }
  }
}))

interface Props {
  handleClose: () => void
  handlePrint: () => void
  propostaSelecionada: ResumoPropostaResponse
  esconderDadosSensiveis?: Boolean
  submitContestacao?: (data: { contestacao: string }) => Promise<void>
  submitAprovacao?: () => void
  handleEnviarParaPaciente?: () => void
  handleAprovadoPeloPaciente?: () => void
  contestacaoLoading?: boolean
  aprovacaoLoading?: boolean
  loadingEnviar?: boolean
  handlePropostaVencedora?: () => void
  valorExames?: number
  verificarSeExiste?: (valor: boolean, texto: string) => string
}

export default function DetalhesPropostaMobile({
  handleClose,
  handlePrint,
  handleEnviarParaPaciente,
  handleAprovadoPeloPaciente,
  loadingEnviar,
  propostaSelecionada,
  esconderDadosSensiveis,
  submitContestacao,
  contestacaoLoading,
  aprovacaoLoading,
  submitAprovacao,
  handlePropostaVencedora,
  valorExames,
  verificarSeExiste
}: Props) {
  const styles = useStyles()
  const [loading, setLoading] = useState(false)

  const [showContestacao, setShowContestacao] = useState(false)

  const history = useHistory()

  useEffect(() => {
    setLoading(true)
    window.scrollTo(0, 60)

    setTimeout(() => setLoading(false), 600)
  }, [])

  function navegarParaPagamento() {
    history.push(`${SynRoutes.resumoPagamento}?p=${propostaSelecionada.id}`)
  }

  function fecharModal() {
    setShowContestacao(false)
  }

  const { register, handleSubmit, errors, watch } = useForm<{
    contestacao: string
  }>({
    defaultValues: {
      contestacao: ''
    },
    resolver: yupResolver(contestacaoSchema)
  })

  const command = watch()

  function obterItensContemplados() {
    var itens = []

    var dados = propostaSelecionada

    itens.push(
      verificarSeExiste(
        !dados?.propostaSemLocalAtendimento,
        'valor do hospital'
      )
    )
    itens.push(verificarSeExiste(dados?.temAnestesia, 'anestesia'))

    itens.push(
      verificarSeExiste(dados?.temPreAnestesica, 'avaliação pré-anestésica')
    )
    itens.push(
      verificarSeExiste(
        dados?.temRiscoCirurgico,
        'avaliação de risco cirúrgico'
      )
    )

    itens.push(verificarSeExiste(dados?.temExames, 'exames pré-operatórios'))
    itens.push(
      verificarSeExiste(dados?.listaOPME?.length !== 0, "OPME's, taxa de OPME")
    )
    itens.push(verificarSeExiste(dados?.temTaxaSyn, 'taxa de serviço'))
    itens.push(
      verificarSeExiste(
        dados?.temReservaSangue,
        'reserva de sangue, bolsa(s) de sangue'
      )
    )

    itens = itens.filter(i => !!i)

    itens.push(
      verificarSeExiste(
        !dados?.propostaSemHonorarioMedico,
        ' honorários equipe médica'
      )
    )

    itens = itens.filter(i => !!i)

    if (itens.length > 0) {
      var texto = itens.slice(0, -1).join(', ')
      texto += (itens.length > 1 ? ' e ' : '') + itens.slice(-1)
      return primeiraLetraMaiusculo(`${texto}`)
    }
  }

  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  function gerarDescricaoDetalhada(item) {
    let partes = []
    if (
      item?.propostaCentroCirurgicoAmbulatorial === true &&
      item?.propostaDiasUteis === true
    ) {
      partes.push(
        'em qualquer dia e horário da semana no centro cirúrgico ambulatorial.'
      )
    }
    if (
      item?.propostaCentroCirurgicoAmbulatorial === true &&
      item?.propostaFinalDeSemana === true
    ) {
      partes.push(
        'somente em finais de semana no centro cirúrgico ambulatorial.'
      )
    }
    if (
      item?.propostaCentroCirurgicoAmbulatorial === false &&
      item?.propostaDiasUteis === true
    ) {
      partes.push('em qualquer dia e horário da semana no centro cirúrgico.')
    }
    if (
      item?.propostaCentroCirurgicoAmbulatorial === false &&
      item?.propostaFinalDeSemana === true
    ) {
      partes.push('somente em finais de semana no centro cirúrgico.')
    }
    if (
      item?.propostaDiasUteis === false &&
      item?.propostaFinalDeSemana === false
    ) {
      partes.push('em qualquer dia e horário no centro cirúrgico.')
    }
    let descricao = 'Para realização '
    descricao += partes
    return descricao
  }

  return (
    <Box className={styles.container}>
      {/* p:{JSON.stringify(propostaSelecionada)} */}
      {!showContestacao ? (
        <>
          <Box>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={() => handleClose()}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6">
                Detalhes da proposta {propostaSelecionada?.codigo}
              </Typography>
            </div>
          </Box>

          <Box mt={3}>
            <Grid container>
              {propostaSelecionada &&
                propostaSelecionada?.status === StatusProposta.VENCEDORA.Id &&
                propostaSelecionada.pagamentoHabilitado && (
                  <Grid item xs={12}>
                    <UBox mb={1}>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => navegarParaPagamento()}
                      >
                        Pagamento
                      </Button>
                    </UBox>
                  </Grid>
                )}
              <Grid item xs={12}>
                {!loading &&
                  ![
                    StatusProposta.ENCERRADA.Id,
                    StatusProposta.RECUSADA_PELO_PACIENTE.Id,
                    StatusProposta.RECUSADA_PELO_MEDICO.Id
                  ].includes(propostaSelecionada?.status) && (
                    <Button
                      style={{ marginBottom: '10px' }}
                      variant="outlined"
                      fullWidth
                      onClick={() => handlePrint()}
                    >
                      Visualizar
                    </Button>
                  )}
              </Grid>
              {!esconderDadosSensiveis &&
                propostaSelecionada?.status ===
                  StatusProposta.APROVADA_PELO_MEDICO.Id && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      loading={loadingEnviar}
                      disabled={loadingEnviar}
                      onClick={() => {
                        handleEnviarParaPaciente()
                        handleClose()
                      }}
                    >
                      Enviar ao paciente
                    </Button>
                  </Grid>
                )}
              {!esconderDadosSensiveis &&
                propostaSelecionada?.status ===
                  StatusProposta.APROVADA_PELO_PACIENTE.Id && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      loading={loadingEnviar}
                      disabled={loadingEnviar}
                      color="secondary"
                      onClick={() => {
                        handlePropostaVencedora()
                        handleClose()
                      }}
                    >
                      Proposta vencedora
                    </Button>
                  </Grid>
                )}

              {!esconderDadosSensiveis &&
                propostaSelecionada?.status ===
                  StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Id && (
                  <Button
                    fullWidth
                    loading={loadingEnviar}
                    disabled={loadingEnviar}
                    onClick={() => {
                      handleAprovadoPeloPaciente()
                      handleClose()
                    }}
                  >
                    Aprovada pelo paciente
                  </Button>
                )}
            </Grid>
          </Box>

          {propostaSelecionada?.status !== 1 && (
            <Box my={3}>
              {propostaSelecionada?.motivo && (
                <Alert severity="error">{propostaSelecionada?.motivo}</Alert>
              )}

              {propostaSelecionada?.status === 2 && (
                <Alert severity="success">O médico aprovou a proposta.</Alert>
              )}

              {propostaSelecionada?.status ===
                StatusProposta.APROVADA_PELO_PACIENTE.Id && (
                <Alert
                  severity="success"
                  className={styles.aprovadoPacienteAlert}
                >
                  {StatusProposta.APROVADA_PELO_PACIENTE.Descricao}
                </Alert>
              )}

              {propostaSelecionada?.status ===
                StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Id && (
                <Alert
                  severity="success"
                  className={styles.aguardandoPacienteAlert}
                >
                  {StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Descricao}
                </Alert>
              )}

              {propostaSelecionada?.status === StatusProposta.VENCEDORA.Id && (
                <Alert
                  severity="success"
                  className={styles.propostaVencedoraAlert}
                >
                  {StatusProposta.VENCEDORA.Descricao}
                </Alert>
              )}
            </Box>
          )}
          <Box my={2}>
            {/* <Typography align="left" variant="h6">
              Hospital
            </Typography> */}
            {esconderDadosSensiveis ? (
              propostaSelecionada.status !==
                StatusProposta.RECUSADA_PELO_PACIENTE.Id && (
                <Card className={styles.hospitalCard}>
                  <Typography variant="h6" align="center">
                    <b>Valor Total da Proposta:</b>
                  </Typography>
                  <Grid item xs={12} style={{ marginTop: '10px' }}>
                    <Typography variant="h6">
                      {formatarDinheiro(propostaSelecionada?.valorTotal)}
                    </Typography>
                  </Grid>
                  <Alert
                    style={{
                      marginBottom: '10px',
                      marginTop: '16px'
                      // textAlign: 'left'
                    }}
                    severity="info"
                  >
                    <Typography variant="body1">
                      <b>O valor total da proposta contempla:</b>
                      <br />
                      {obterItensContemplados()}
                    </Typography>
                  </Alert>
                </Card>
              )
            ) : (
              <Card className={styles.hospitalCard}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" align="left">
                      <b>{propostaSelecionada?.localAtendimento}</b>
                    </Typography>
                    <Box>
                      <Grid container>
                        <Grid item xs={12} style={{ padding: '5px' }}>
                          <Typography
                            variant="subtitle2"
                            className={styles.hositalCardText}
                            align="left"
                          >
                            Valor hospital:
                          </Typography>

                          <Typography align="left" variant="body2">
                            {propostaSelecionada?.valorSala
                              ? formatarDinheiro(propostaSelecionada?.valorSala)
                              : 'Não informado'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '5px' }}>
                          <Typography
                            variant="subtitle2"
                            className={styles.hositalCardText}
                            align="left"
                          >
                            Valor anestesia:
                          </Typography>

                          <Typography align="left" variant="body2">
                            {propostaSelecionada?.valorAnestesia
                              ? formatarDinheiro(
                                  propostaSelecionada?.valorAnestesia || 0
                                )
                              : 'Não informado'}
                          </Typography>
                        </Grid>
                        {propostaSelecionada?.valorBolsaSangue !== null && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              Valor bolsa de sangue:
                            </Typography>

                            <Typography align="left" variant="body2">
                              {propostaSelecionada?.valorBolsaSangue
                                ? formatarDinheiro(
                                    propostaSelecionada?.valorBolsaSangue || 0
                                  )
                                : 'R$ 0,00'}
                            </Typography>
                          </Grid>
                        )}
                        {propostaSelecionada?.valorReservaSangue !== null && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              Valor reserva de sangue:
                            </Typography>

                            <Typography align="left" variant="body2">
                              {propostaSelecionada?.valorReservaSangue
                                ? formatarDinheiro(
                                    propostaSelecionada?.valorReservaSangue || 0
                                  )
                                : 'R$ 0,00'}
                            </Typography>
                          </Grid>
                        )}
                        {propostaSelecionada?.valorPreAnestesica !== null && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              Valor avaliação pré-anestésica:
                            </Typography>

                            <Typography align="left" variant="body2">
                              {propostaSelecionada?.valorPreAnestesica
                                ? formatarDinheiro(
                                    propostaSelecionada?.valorPreAnestesica || 0
                                  )
                                : 'R$ 0,00'}
                            </Typography>
                          </Grid>
                        )}
                        {propostaSelecionada?.valorRiscoCirurgico !== null && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              Valor avaliação risco cirúrgico:
                            </Typography>

                            <Typography align="left" variant="body2">
                              {propostaSelecionada?.valorRiscoCirurgico
                                ? formatarDinheiro(
                                    propostaSelecionada?.valorRiscoCirurgico ||
                                      0
                                  )
                                : 'R$ 0,00'}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12} style={{ padding: '5px' }}>
                          <Typography
                            variant="subtitle2"
                            className={styles.hositalCardText}
                            align="left"
                          >
                            Total OPME:
                          </Typography>

                          <Typography align="left" variant="body2">
                            {formatarDinheiro(
                              propostaSelecionada?.valorTotalOPME
                            ) || ' R$ 0,00'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '5px' }}>
                          <Typography
                            variant="subtitle2"
                            className={styles.hositalCardText}
                            align="left"
                          >
                            Taxa OPME ({propostaSelecionada?.taxaOPME}
                            %):
                          </Typography>

                          <Typography align="left" variant="body2">
                            {formatarDinheiro(propostaSelecionada?.valorOPME) ||
                              ' R$ 0,00'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '5px' }}>
                          <Typography
                            variant="subtitle2"
                            className={styles.hositalCardText}
                            align="left"
                          >
                            Taxa de serviço (
                            {propostaSelecionada?.taxaSYN?.toFixed(2)}
                            %):
                          </Typography>
                          <Typography align="left" variant="body2">
                            {formatarDinheiro(
                              propostaSelecionada?.valorComissaoSYN
                            ) || ' R$ 0,00'}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={12} style={{ padding: '5px' }}>
                          <Typography
                            variant="subtitle2"
                            className={styles.hositalCardText}
                            align="left"
                          >
                            Exames pré-operatórios:
                          </Typography>
                          <Typography align="left" variant="body2">
                            {formatarDinheiro(
                              propostaSelecionada?.valorExamesPreOperatorios
                            ) || ' R$ 0,00'}
                          </Typography>
                        </Grid> */}
                        <Grid item xs={12} style={{ padding: '5px' }}>
                          <Typography
                            variant="subtitle2"
                            className={styles.hositalCardText}
                            align="left"
                          >
                            Honorários equipe médica:
                          </Typography>
                          <Typography align="left" variant="body2">
                            {formatarDinheiro(
                              propostaSelecionada?.honorarioMedico
                            ) || ' R$ 0,00'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={styles.cardDivider} />
                          <Typography variant="h6">
                            <strong>Valor Total:</strong>{' '}
                            {formatarDinheiro(propostaSelecionada?.valorTotal)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            )}

            <UBox
              style={{ marginBottom: '10px' }}
              render={
                propostaSelecionada.propostaCentroCirurgicoAmbulatorial ===
                  true ||
                propostaSelecionada.propostaFinalDeSemana === true ||
                propostaSelecionada.propostaDiasUteis === true
              }
            >
              <Alert
                severity="warning"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '8px'
                }}
                iconMapping={{
                  warning: <FiInfo style={{ color: 'orange' }} />
                }}
              >
                {gerarDescricaoDetalhada(propostaSelecionada)}
                <br />
                {propostaSelecionada.propostaDiasUteis === true &&
                  'segunda a sábado até as 12h.'}
                {propostaSelecionada.propostaFinalDeSemana === true &&
                  'sábado a partir de 12h até domingo 23h59 e feriados.'}
              </Alert>
            </UBox>

            <UBox render={propostaSelecionada?.informacaoExame?.length > 0}>
              <Alert
                style={{
                  marginBottom: '10px',
                  marginTop: '16px',
                  width: '100%'
                  // textAlign: 'left'
                }}
                className={styles.boxAlert}
                severity="info"
              >
                <Typography variant="body1">
                  {propostaSelecionada?.informacaoExame}
                </Typography>
              </Alert>
            </UBox>

            {propostaSelecionada.observacaoHospital && (
              <Alert
                style={{
                  marginBottom: '10px',
                  marginTop: '16px',
                  width: '100%'
                  // textAlign: 'left'
                }}
                className={styles.boxAlert}
                severity="info"
              >
                <Typography variant="body1">
                  <b>Observações:</b>
                  <br />
                  Hospital: {propostaSelecionada?.observacaoHospital}
                  <br />
                </Typography>
              </Alert>
            )}

            {propostaSelecionada?.propostaSemHonorarioMedico && (
              <Box width="100%" textAlign="left" my={2}>
                <Box mt={2}>
                  <Alert severity="info">
                    <Typography variant="subtitle2">
                      Proposta não contempla honorários da equipe médica.
                    </Typography>
                  </Alert>
                </Box>
              </Box>
            )}
          </Box>

          <Box width="100%" textAlign="left" my={2}>
            <Box mt={2}>
              <Alert severity="info">
                <p style={{ marginTop: 0 }}>
                  Não abrangem OPME's, materiais, perneiras, manta térmica e
                  exames não previstos na proposta, além de intervenções de
                  outras especialidades, reoperações, hemoterapia, nutrição
                  especial, quimioterápicos e terapias renais.{' '}
                </p>
                <p>
                  Cobranças adicionais incluem diárias extras de enfermaria por
                  R$ 1.600 e de apartamento por R$ 1.800, além de diárias de UTI
                  não programadas, que serão cobradas como conta aberta.{' '}
                </p>
                <p>
                  O orçamento é válido para cirurgias sem intercorrências e para
                  pacientes sem alergia a látex; quaisquer complicações serão
                  cobradas como conta aberta (direto com o hospital).
                </p>
              </Alert>
            </Box>
          </Box>

          {propostaSelecionada?.status ===
            StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Id && (
            <Box mt={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    style={{ ...btnRed, margin: '10px 0' }}
                    size="large"
                    color="default"
                    variant="outlined"
                    fullWidth
                    onClick={() => setShowContestacao(true)}
                  >
                    Recusar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => submitAprovacao()}
                    loading={aprovacaoLoading}
                    disabled={aprovacaoLoading}
                  >
                    Aprovar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Box mb={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Por favor, conte-nos o motivo por ter recusado
              </Typography>
            </Grid>
          </Box>
          <form onSubmit={handleSubmit(submitContestacao)}>
            <TextField
              erro={errors}
              multiline
              rows={6}
              name="contestacao"
              label="Motivação"
              inputRef={register}
              value={command?.contestacao}
            />
            <Box my={4}>
              <Button
                size="large"
                variant="contained"
                color="default"
                fullWidth
                onClick={() => setShowContestacao(false)}
                type="reset"
              >
                Cancelar
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                style={{ marginTop: '10px' }}
                fullWidth
                type="submit"
                loading={contestacaoLoading}
                disabled={contestacaoLoading}
                onClick={() => {
                  if (command?.contestacao.length > 3) {
                    setTimeout(fecharModal, 3000)
                  }
                }}
              >
                Enviar
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </Box>
  )
}
