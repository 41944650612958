import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { Autocomplete } from 'src/components/ui/Autocomplete';
import TextField from 'src/components/ui/TextField';
import { TIPO_CONTA } from 'src/core/consts/tipoDadosBancario';
import codigoBancos from 'src/core/consts/listaBancos'

type Props = {
  control?: any;
  errors?: any;
  register?: any;
  command?: any;
  handleItemValue?: any;
  isPJ?: boolean;
  handleRadioChange?: any;
  editarDadosBancarios?: any;
};

export default function DadosBancarios({
  control,
  errors,
  register,
  command,
  handleItemValue,
  isPJ,
  handleRadioChange,
  editarDadosBancarios
}: Props) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <TextField
          inputRef={register}
          erro={errors}
          name="nomeBanco"
          placeholder="Nome do titular"
          label={isPJ ? 'Razão social' : 'Titular'}
          inputProps={{ maxLength: 30 }}
          value={command.nomeBanco}
          showCaracteres={30}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          erro={errors}
          name="cpfCnpj"
          placeholder="Informe documento"
          label={isPJ ? 'CNPJ' : 'CPF'}
          disabled={!!editarDadosBancarios}
          hiddenLabel
          control={control}
          mask={isPJ ? '99.999.999/9999-99' : '999.999.999-99'}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          key={command.codigoBanco}
          erro={errors}
          name="codigoBanco"
          label="código banco"
          closeOnSelect
          value={
            command.codigoBanco !== undefined
              ? codigoBancos.find(w => w.id === command.codigoBanco)
              : codigoBancos[0]
          }
          options={codigoBancos}
          getOptionLabel={option => `${option.id} - ${option.descricao}`}
          onChange={(event, newValue) => {
            handleItemValue('codigoBanco', newValue?.id.toString() || '');
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          inputRef={register}
          erro={errors}
          name="agencia"
          label="Agência"
          placeholder="Número da agencia"
          defaultValue=""
          inputProps={{ maxLength: 4 }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          inputRef={register}
          erro={errors}
          inputProps={{ maxLength: 1 }}
          name="digitoAgencia"
          label="Dígito da agência"
          placeholder="Digito da agencia"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          inputRef={register}
          erro={errors}
          name="numeroConta"
          placeholder="Número da conta"
          inputProps={{ maxLength: 13 }}
          label="Conta"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          inputRef={register}
          erro={errors}
          inputProps={{ maxLength: 2 }}
          placeholder="Digito da conta"
          name="digitoConta"
          label="Dígito da conta"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <label>Tipo de conta:</label>
        <RadioGroup value={command.tipoConta}
          onChange={handleRadioChange}
          style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            control={
              <Radio
                value={TIPO_CONTA.CORRENTE}
                color="primary"
                name="tipoConta"
              />
            }
            label="Conta Corrente"
          />
          <FormControlLabel
            control={
              <Radio
                value={TIPO_CONTA.POUPANCA}
                color="primary"
                name="tipoConta"
              />
            }
            label="Poupança"
          />
        </RadioGroup>
        {errors?.tipoConta && (
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
            {errors?.tipoConta?.message}
          </p>
        )}
      </Grid>
    </Grid>
  );
}
