import { yupResolver } from '@hookform/resolvers/yup'
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Grid
} from '@material-ui/core'

import Close from '@material-ui/icons/Close'
import React, { ReactElement, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import { showNotificationAvisoAPI } from 'src/core/helpers/notification'
import PasswordField from 'src/components/ui/PasswordField'
import { validarAcesso } from 'src/api/usuario'
import { SenhaMasterCommand } from 'src/types'
import { verificarCPF, onlyNumber } from 'src/core/helpers/common'
import { Alert } from '@material-ui/lab'

interface Props {
  copy: string
  titulo: string
  open: boolean
  handleClose: () => void
  // onAbrirDetalhes?: (ehAbrir) => void
  onConfirm?: (ehConfirm) => void
  isDelete?: boolean
  confirmButtonText?: string
  cancelButtonText?: string
  infoText?: ReactElement
}

export default function ModalLiberarAcesso({
  copy,
  titulo,
  open,
  handleClose,
  // onAbrirDetalhes,
  onConfirm,
  confirmButtonText,
  cancelButtonText,
  infoText,
  isDelete
}: Props) {
  const { watch, register, errors, control, handleSubmit } = useForm<{
    cpf: string
    senha: string
    senhaMaster: string
  }>({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        cpf: yup
          .string()
          .test('cpf-teste', 'CPF inválido', value => {
            if (!value) return false

            return verificarCPF(onlyNumber(value))
          })
          .required()
          .label('CPF'),
        senha: yup.string().required().label('Senha'),
        senhaMaster: yup.string().required().label('Senha master')
      })
    )
  })
  const [loading, setLoading] = useState(false)
  const command = watch()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const onSubmit = async () => {
    setLoading(true)
    let body: SenhaMasterCommand = {
      cpf: command.cpf,
      senha: command.senha,
      senhaMaster: command.senhaMaster
    }

    validarAcesso(body)
      .then(() => {
        handleClose()
        // onAbrirDetalhes(true)
        onConfirm(true)
      })
      .catch(error => {
        // onAbrirDetalhes(false)
        onConfirm(false)
        showNotificationAvisoAPI(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        {fullScreen && (
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <Typography style={{ flex: 1 }} variant="h6"></Typography>
              <Button autoFocus variant="text" type="submit" color="inherit">
                Salvar
              </Button>
            </Toolbar>
          </AppBar>
        )}
        {/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
        <DialogContent>
          <Box mb={2} mt={1}>
            <Typography variant="h6">{titulo}</Typography>
          </Box>

          <Box mb={2}>
            <Typography variant="body1">{infoText}</Typography>
          </Box>

          <Alert severity="info">{copy}</Alert>

          <TextField
            erro={errors}
            autoFocus
            name="cpf"
            label="CPF"
            mask={'999.999.999-99'}
            control={control}
            type="text"
          />

          <PasswordField
            erro={errors}
            name="senha"
            label="Senha"
            inputRef={register}
          />

          <PasswordField
            erro={errors}
            name="senhaMaster"
            label="Senha Master"
            inputRef={register}
          />
        </DialogContent>
        {!fullScreen && (
          <DialogActions>
            <Box width="100%" p={2} pt={0}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {isDelete ? (
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="submit"
                      style={{ backgroundColor: '#cc3025' }}
                      // color="#f44336"
                      size="large"
                      fullWidth
                    >
                      {confirmButtonText || 'Excluir'}
                    </Button>
                  ) : (
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="submit"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      Liberar
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    color="default"
                    size="large"
                    fullWidth
                  >
                    {cancelButtonText || 'Sair'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogActions>
        )}
      </form>
    </Dialog>
  )
}
