import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  DialogProps,
  Grid,
  useMediaQuery,
  useTheme,
  TextField as TextFieldMU
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import Address from '../address/address'
import DadosBasicos from '../basico/dadosBasicos'
import { ObterCep } from 'src/api/cep/cep'
import {
  onlyNumber,
  verificarCNPJ,
  verificarCPF
} from 'src/core/helpers/common'
import moment from 'moment'
import UBox from 'src/components/ui/Ubox'
import { NumberFormatCustom } from 'src/components/ui/NumberField'
import { IFormsCadastro } from '../interface'
import HttpsIcon from '@material-ui/icons/Https'
import * as S from './styles'
import DadosBancarios from '../dados-bancarios/dadosBancarios'
import { Alert } from '@material-ui/lab'
import ModalLiberarAcesso from 'src/components/modals/ModalSenhaMaster'

const schema = yup.object().shape({
  nomeBanco: yup.string().max(30).required().label('Titular da conta'),
  cpfCnpj: yup
    .string()
    .test('cnpj-teste', 'Documento inválido', value => {
      if (!value) return false
      const documento = onlyNumber(value)
      return documento.length > 11
        ? verificarCNPJ(documento)
        : verificarCPF(documento)
    })
    .required()
    .label('Documento'),
  codigoBanco: yup.string().required().label('Código do banco'),
  agencia: yup.string().max(4).required().label('Agência'),
  digitoAgencia: yup.string().max(1).label('Dígito da agência'),
  numeroConta: yup.string().max(13).required().label('Número da conta'),
  digitoConta: yup.string().max(2).required().label('Dígito da conta'),
  tipoConta: yup.string().label('Tipo de conta'),

  companyName: yup.string().required('Nome fantasia obrigatório'),
  tradingName: yup.string().label('Razão social obrigatória'),
  companyEmail: yup
    .string()
    .email('E-mail inválido')
    .required('E-mail obrigatório'),
  companyDocument: yup
    .string()
    .test('cnpj-teste', 'Documento inválido', value => {
      if (!value) return false
      const documento = onlyNumber(value)
      return documento.length > 11
        ? verificarCNPJ(documento)
        : verificarCPF(documento)
    })
    .required()
    .label('Documento'),
  annualRevenue: yup.string().required('Receita anual obrigatória'),

  companyPhoneNumbers: yup
    .string()
    .required()
    .test('valida-telefone', 'O telefone é inválido', value => {
      return onlyNumber(value).length >= 10
    })
    .label('Telefone'),

  companyCep: yup.string().required('CEP obrigatório'),
  companyStreet: yup.string().required('Rua obrigatória'),
  companyComplementary: yup.string().required('Complemento obrigatório'),
  companyStreetNumber: yup.string().required('Número obrigatório'),
  companyNeighborhood: yup.string().required('Bairro obrigatório'),
  companyCity: yup.string().required('Cidade obrigatória'),
  companyState: yup.string().required('Estado obrigatório'),
  companyReferencePoint: yup
    .string()
    .required('Ponto de referência obrigatório'),

  name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  document: yup
    .string()
    .test('cnpj-teste', 'Documento inválido', value => {
      if (!value) return false
      const documento = onlyNumber(value)
      return documento.length > 11
        ? verificarCNPJ(documento)
        : verificarCPF(documento)
    })
    .required()
    .label('Documento'),
  birthdate: yup
    .string()
    .required()
    .test('nas', 'Por favor escolha uma data valida', value => {
      if (value.replace(' ', '').length < 10) return false
      return moment(value, 'DD/MM/YYYY').isBetween(
        '1922-12-31',
        moment(),
        'day'
      )
    })
    .label('Data de nascimento'),
  monthlyIncome: yup
    .string()
    .required()
    .label('Valor da renda')
    .test('renda', 'O valor da renda não pode ser R$0,00', value => {
      return Number(value) !== 0
    }),
  selfDeclaredLegalRepresentative: yup
    .boolean()
    .required()
    .label('Se identificar como representante legal')
    .test(
      'representante',
      'A verificação de representante deve ser marcado!',
      value => {
        return value
      }
    ),
  professionalOccupation: yup
    .string()
    .required('Ocupação profissional obrigatória'),
  phoneNumbers: yup
    .string()
    .required()
    .test('valida-telefone', 'O telefone é inválido', value => {
      return onlyNumber(value).length >= 10
    })
    .label('Telefone'),

  cep: yup.string().required('CEP obrigatório'),
  street: yup.string().required('Rua obrigatória'),
  complementary: yup.string().required('Complemento obrigatório'),
  streetNumber: yup.string().required('Número obrigatório'),
  neighborhood: yup.string().required('Bairro obrigatório'),
  city: yup.string().required('Cidade obrigatória'),
  state: yup.string().required('Estado obrigatório'),
  referencePoint: yup.string().required('Ponto de referência obrigatório')
})

interface Props extends DialogProps {
  open: boolean
  onClose?: () => void
  dadosBancariosUsuario: (data: IFormsCadastro) => void
  ignorarSubmit?: boolean
  showNext?: () => void
  showBack?: () => void
  cpfInformado?: string
  editarDadosBancarios?: any;
}

export default function FormPJ(props: Props) {
  const {
    onClose,
    dadosBancariosUsuario,
    ignorarSubmit,
    showBack,
    showNext,
    editarDadosBancarios
  } = props
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingCep, setLoadingCep] = useState(false)
  const [viewButton, setViewButton] = useState(true)
  const [podeSalvar, setPodeSalvar] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const phoneNumbers = editarDadosBancarios?.registerInformation?.phone_numbers[0].ddd + editarDadosBancarios?.registerInformation?.phone_numbers[0].number || ''
  const phoneNumberRepresentante = editarDadosBancarios?.registerInformation?.managing_partners[0]?.phone_numbers[0].ddd + editarDadosBancarios?.registerInformation?.managing_partners[0]?.phone_numbers[0].number || ''

  const {
    register,
    errors,
    handleSubmit,
    control,
    watch,
    trigger,
    setValue
  } = useForm<IFormsCadastro>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      nomeBanco: editarDadosBancarios?.nomeBanco || '',
      cpfCnpj: editarDadosBancarios?.cpfCnpj || '',
      codigoBanco: editarDadosBancarios?.codigoBanco || '',
      agencia: editarDadosBancarios?.agencia || '',
      digitoAgencia: editarDadosBancarios?.digitoAgencia || '',
      numeroConta: editarDadosBancarios?.numeroConta || '',
      digitoConta: editarDadosBancarios?.digitoConta || '',
      tipoConta: editarDadosBancarios?.tipoConta || '',

      companyName: editarDadosBancarios?.registerInformation?.company_name || '',
      tradingName: editarDadosBancarios?.registerInformation?.trading_name || '',
      companyEmail: editarDadosBancarios?.registerInformation?.email || '',
      companyDocument: editarDadosBancarios?.registerInformation?.document || '',
      annualRevenue: editarDadosBancarios?.registerInformation?.annual_revenue || null,
      companyPhoneNumbers: phoneNumbers || '',

      companyCep: editarDadosBancarios?.registerInformation?.main_address?.zip_code || '',
      companyStreet: editarDadosBancarios?.registerInformation?.main_address?.street || '',
      companyComplementary: editarDadosBancarios?.registerInformation?.main_address?.complementary || '',
      companyStreetNumber: editarDadosBancarios?.registerInformation?.main_address?.street_number || '',
      companyNeighborhood: editarDadosBancarios?.registerInformation?.main_address?.neighborhood || '',
      companyCity: editarDadosBancarios?.registerInformation?.main_address?.city || '',
      companyState: editarDadosBancarios?.registerInformation?.main_address?.state || '',
      companyReferencePoint: editarDadosBancarios?.registerInformation?.main_address?.reference_point || '',

      name: editarDadosBancarios?.registerInformation?.managing_partners[0]?.name || '',
      email: editarDadosBancarios?.registerInformation?.managing_partners[0]?.email || '',
      document: editarDadosBancarios?.registerInformation?.managing_partners[0]?.document || '',
      birthdate: editarDadosBancarios?.registerInformation?.managing_partners[0]?.birthdate || '',
      monthlyIncome: editarDadosBancarios?.registerInformation?.managing_partners[0]?.monthly_income || null,
      professionalOccupation: editarDadosBancarios?.registerInformation?.managing_partners[0]?.professional_occupation || '',
      phoneNumbers: phoneNumberRepresentante || '',
      selfDeclaredLegalRepresentative: editarDadosBancarios?.registerInformation?.managing_partners[0]?.selfDeclaredLegalRepresentative || false,

      cep: editarDadosBancarios?.registerInformation?.managing_partners[0]?.address?.zip_code || '',
      street: editarDadosBancarios?.registerInformation?.managing_partners[0]?.address?.street || '',
      complementary: editarDadosBancarios?.registerInformation?.managing_partners[0]?.address?.complementary || '',
      streetNumber: editarDadosBancarios?.registerInformation?.managing_partners[0]?.address?.street_number || '',
      neighborhood: editarDadosBancarios?.registerInformation?.managing_partners[0]?.address?.neighborhood || '',
      city: editarDadosBancarios?.registerInformation?.managing_partners[0]?.address?.city || '',
      state: editarDadosBancarios?.registerInformation?.managing_partners[0]?.address?.state || '',
      referencePoint: editarDadosBancarios?.registerInformation?.managing_partners[0]?.address?.reference_point || ''
    }
  })

  const command = watch()

  const onSubmit = async (data: IFormsCadastro) => {
    setLoading(true)
    if (ignorarSubmit) {
      dadosBancariosUsuario(data)
      setViewButton(true)
      showNext()
    } else {
      dadosBancariosUsuario(data);
    }
    setTimeout(() => setLoading(false), 2000)
  }

  async function buscarCep(cep, ehEmpresa = false) {
    try {
      let cepNumeros = onlyNumber(cep)
      if (cepNumeros.length === 8) {
        setLoadingCep(true)
        const { data } = await ObterCep(cepNumeros)

        if (data?.localidade) {
          setValue(ehEmpresa ? 'companyCity' : 'city', data.localidade, {
            shouldValidate: true
          })
        }
        if (data?.logradouro) {
          setValue(ehEmpresa ? 'companyStreet' : 'street', data.logradouro, {
            shouldValidate: true
          })
        }

        if (data?.uf) {
          setValue(
            ehEmpresa ? 'companyState' : 'state',
            data.uf.toUpperCase(),
            { shouldValidate: true }
          )
        }
        if (data?.bairro) {
          setValue(
            ehEmpresa ? 'companyNeighborhood' : 'neighborhood',
            data.bairro.toUpperCase(),
            {
              shouldValidate: true
            }
          )
        }
        setLoadingCep(false)
      }
    } catch (error) {
      setLoadingCep(false)
    }
  }

  const handleChechedItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setValue(name as keyof IFormsCadastro, checked, { shouldValidate: true })
  }

  const handleItemValue = (name: keyof IFormsCadastro, item) => {
    setValue(name, item, { shouldValidate: true })
  }

  useEffect(() => {
    buscarCep(command.cep)
  }, [command.cep])

  useEffect(() => {
    buscarCep(command.companyCep, true)
  }, [command.companyCep])

  useEffect(() => {
    register('annualRevenue')
    register('monthlyIncome')
    register('selfDeclaredLegalRepresentative')
    register('nomeBanco')
    register('cpfCnpj')
    register('codigoBanco')
    register('agencia')
    register('digitoAgencia')
    register('numeroConta')
    register('digitoConta')
    register('tipoConta')
  }, [register])

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleItemValue('tipoConta', event.target.value)
  }

  const handleNext = async () => {
    let isValid
    switch (step) {
      case 0:
        isValid = await trigger([
          'nomeBanco',
          'cpfCnpj',
          'codigoBanco',
          'agencia',
          'digitoAgencia',
          'numeroConta',
          'digitoConta',
          'tipoConta'
        ])
        if (isValid) {
          setStep(1)
        }
        break
      case 1:
        isValid = await trigger([
          'companyName',
          'tradingName',
          'companyEmail',
          'companyDocument',
          'annualRevenue',
          'companyPhoneNumbers'
        ])
        if (isValid) {
          setStep(2)
        }
        break
      case 2:
        isValid = await trigger([
          'companyCep',
          'companyStreet',
          'companyComplementary',
          'companyStreetNumber',
          'companyNeighborhood',
          'companyCity',
          'companyState',
          'companyReferencePoint'
        ])
        if (isValid) {
          setStep(3)
        }
        break
      case 3:
        isValid = await trigger([
          'name',
          'email',
          'document',
          'birthdate',
          'monthlyIncome',
          'professionalOccupation',
          'phoneNumbers',
          'selfDeclaredLegalRepresentative'
        ])
        if (isValid) {
          setStep(4)
        }
        break
      default:
        break
    }
  }

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  useEffect(() => {
    if (command.cpfCnpj) {
      setValue('companyDocument', command.cpfCnpj, { shouldValidate: true })
    }
  }, [command.cpfCnpj])

  useEffect(() => {
    if (command.nomeBanco) {
      setValue('tradingName', command.nomeBanco, { shouldValidate: true })
    }
  }, [command.nomeBanco])

  const handleDesbloquear = () => {
    setShowModal(true)
  }

  function dadosModal() {
    return {
      copy:
        'A funcionalidade de editar dados bancário exige autorização master',
      titulo: 'Acesso Restrito',
      open: showModal,
      handleClose: () => setShowModal(false),
      onConfirm: ehConfirm => setPodeSalvar(ehConfirm)
    }
  }

  return (
    <>
      <ModalLiberarAcesso {...dadosModal()} />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginY={2}>
              <span style={{ fontSize: '20px', fontWeight: 500, color: '#455890' }}>
                {step === 0 ? 'Dados Bancários' : step === 1
                  ? 'Informações da Empresa' : step === 2
                    ? 'Endereço da Empresa' : step === 3
                      ? 'Informações do Representante' : 'Endereço do Representante'}
              </span>
              <span
                style={{ fontSize: '14px', fontWeight: 500, color: '#a4a5a8' }}
              >
                Passo {step + 1} de 5
              </span>
            </Box>
          </Grid>

          <UBox hidden={step !== 0}>
            <>
              <DadosBancarios
                control={control}
                errors={errors}
                register={register}
                command={command}
                isPJ={true}
                handleItemValue={handleItemValue}
                handleRadioChange={handleRadioChange}
                editarDadosBancarios={editarDadosBancarios}
              />
            </>
          </UBox>

          <UBox hidden={step !== 1}>
            <>
              <Alert severity="info">
                {' '}
                A razão social e o CNPJ deve ser o mesmo dos dados bancários.
              </Alert>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <TextField
                    control={control}
                    erro={errors}
                    inputRef={register}
                    name="companyName"
                    label="Nome fantasia *"
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    disabled={command.tradingName.length > 0}
                    control={control}
                    erro={errors}
                    inputRef={register}
                    name="tradingName"
                    label="Razão social *"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    control={control}
                    inputRef={register}
                    erro={errors}
                    name="companyEmail"
                    label="E-mail *"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    size="medium"
                    erro={errors}
                    type="tel"
                    maskTelCel
                    control={control}
                    inputProps={{ maxLength: 100, minLength: 10 }}
                    name="companyPhoneNumbers"
                    label="Telefone *"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    erro={errors}
                    name="companyDocument"
                    placeholder="Informe número do CNPJ"
                    label="Número do CNPJ *"
                    hiddenLabel
                    disabled={command.companyDocument.length > 0}
                    control={control}
                    mask="99.999.999/9999-99"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  {/* {command.cpfCnpj.length > 0 && (
                    <p style={{ fontSize: 12, color: '#a4a5a8' }}>
                      O CNPJ deve ser o mesmo dos dados bancários.
                    </p>
                  )} */}
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextFieldMU
                    data-cy="annualRevenue"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    label="Faturamento anual *"
                    error={!!errors.annualRevenue}
                    helperText={errors.annualRevenue?.message}
                    onFocus={e => e.target.select()}
                    value={command.annualRevenue}
                    defaultValue={command.annualRevenue}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value
                      handleItemValue('annualRevenue', value)
                    }}
                  />
                </Grid>
              </Grid>
            </>
          </UBox>

          <UBox hidden={step !== 2}>
            <>
              <Address
                control={control}
                errors={errors}
                register={register}
                loadingCep={loadingCep}
                type="company"
              />
            </>
          </UBox>

          <UBox hidden={step !== 3}>
            <>
              <DadosBasicos
                control={control}
                errors={errors}
                register={register}
                command={command}
                isPJ={true}
                handleItemValue={handleItemValue}
                handleChechedItem={handleChechedItem}
                editarDadosBancarios={editarDadosBancarios}
              />
            </>
          </UBox>

          <UBox hidden={step !== 4}>
            <>
              <Address
                control={control}
                errors={errors}
                register={register}
                loadingCep={loadingCep}
                type="normal"
              />
            </>
          </UBox>
        </Grid>

        {viewButton && (
          <Box style={{ display: 'flex', gap: '12px', marginTop: '16px' }}>
            {step !== 0 && (
              <Grid item xs={12} sm={12}>
                <Button
                  disabled={loading}
                  type="button"
                  fullWidth
                  variant="contained"
                  color="default"
                  onClick={handleBack}
                >
                  Voltar
                </Button>
              </Grid>
            )}
            {ignorarSubmit && (
              <>
                {step === 0 && (
                  <Grid item xs={12} sm={12}>
                    <Button
                      disabled={loading}
                      type="button"
                      fullWidth
                      variant="contained"
                      color="default"
                      onClick={showBack}
                    >
                      Voltar
                    </Button>
                  </Grid>
                )}
              </>
            )}
            {ignorarSubmit && (
              <>
                {step !== 4 ? (
                  <Grid item xs={12} sm={12}>
                    <Button
                      disabled={loading}
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Continuar
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12}>
                    <Button
                      loading={loading}
                      disabled={loading}
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Continuar
                    </Button>
                  </Grid>
                )}
              </>
            )}

            {!ignorarSubmit && (
              <>
                {step !== 4 ? (
                  <Grid item xs={12} sm={12}>
                    {!!editarDadosBancarios ? (
                      <>
                        {podeSalvar ? (
                          <Button
                            disabled={loading}
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            Continuar
                          </Button>) : (
                          <Button
                            variant="contained"
                            color="secondary"
                            type="button"
                            fullWidth
                            onClick={handleDesbloquear}
                            startIcon={<HttpsIcon />}
                          >
                            Desbloquear
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        disabled={loading}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Continuar
                      </Button>
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12}>
                    <Button
                      loading={loading}
                      disabled={loading}
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Salvar
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Box>
        )}
      </form>
    </>
  )
}
