import React from 'react'

import NumberFormat from 'react-number-format'

import { Container, Calendar, Icon } from 'src/styles/components/dateInput'
import { TextField } from '@material-ui/core'
import CalendarToday from '@material-ui/icons/CalendarToday'

import moment from 'moment'
interface Props {
  value: string
  errors: any
  name: string
  label: string
  edicao?: boolean
  control: any
  handleChange: (v: any) => void
}

export default function HourInput({
  value,
  name,
  errors,
  label,
  control,
  handleChange
}: Props) {
  function limit(val, max) {
    if (val.length === 1 && val[0] > max[0]) {
      val = '0' + val
    }

    if (val.length === 2) {
      if (Number(val) === 0) {
        val = '00'

        //this can happen when user paste number
      } else if (val > max) {
        val = max
      }
    }

    return val
  }

  function arrendondarPara5(num) {
    let numero = parseInt(num) | 0

    let aproximado = Math.ceil(numero / 5) * 5

    return aproximado > 55 ? 55 : aproximado
  }

  function hourExpiry(val) {
    let hour = limit(val.substring(0, 2), '23')
    let minutes = limit(val.substring(2, 4), '59')

    return `${hour}:${minutes}`
  }
  function verifyHour(value, isBlur = false) {
    var hour = value.replaceAll('_', '')

    if (hour.length < 'H'.length || hour.length > 'HHMM'.length) {
      handleChange('')
      return
    }

    if (!moment(hour, 'HHmm').isValid()) {
      handleChange('')
    } else {
      if (
        (isBlur && hour.length >= 'H'.length && hour.length <= 'HHmm'.length) ||
        hour.length === 'HHmm'.length
      ) {
        var horas = hour.substring(0, 2)
        var minutos = arrendondarPara5(hour.substring(2, 4))
        handleChange(moment(`${horas}${minutos}`, 'HHmm').format('HH:mm'))
      } else {
        if (hour.length === 'HHmm'.length) handleChange(hour)
      }
    }
  }
  return (
    <Container>
      <NumberFormat
        format={hourExpiry}
        mask="_"
        suffix="h"
        customInput={TextField}
        label={label}
        error={!!errors[name]}
        helperText={errors[name]?.message}
        placeholder="Ex: 14:25"
        fullWidth
        onChange={e => {
          let input = e.target.value
          var hour = input.replaceAll('_', '').replaceAll(':', '')
          verifyHour(hour)
        }}
        onBlur={e => {
          let input = e.target.value
          var hour = input.replaceAll('_', '').replaceAll(':', '')
          verifyHour(hour, true)
        }}
        variant="outlined"
        margin="normal"
        value={!!value && `${value}h`}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Container>
  )
}
