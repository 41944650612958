import { createContext } from 'react'
import {
  PagamentoBoletoResponse,
  PagamentoResumoResponse,
  Parcelamento
} from 'src/api/pagamento'
import { ICartaoForm } from 'src/components/pagamento/cartaoCredito'
import { IDadosPessoais } from 'src/components/pagamento/formDadosPessoais'

// ✅ with Type Alias
export type T_FORMAS_PAGAMENTO =
  | 'pix'
  | 'boleto'
  | 'credit_card'
  | 'multiplas'
  | null
export interface IPagamentoContext {
  propostaId?: string
  loading: boolean
  loadingResumo: boolean
  loadingPagamento: boolean
  loadingPix: boolean
  loadingBoleto: boolean
  loadingCartao: boolean
  existePagamentoFracionado: boolean

  resumo?: PagamentoResumoResponse
  dadosPessoais?: IDadosPessoais
  urlPix?: string
  pixCopiaCola?: string
  pixCopiaColaFracionado?: string
  urlPixFracionado?: string
  pagamentoCreditoFracionado?: boolean
  boleto?: PagamentoBoletoResponse
  boletoFracionado?: PagamentoBoletoResponse
  formaPagamento: T_FORMAS_PAGAMENTO
  parcelamento: Parcelamento
  loadingParcelas: boolean
  obterResumoPagamento: (
    propostaId: string,
    loading?: boolean
  ) => Promise<boolean>
  selecionarFormaPagamento: (forma: T_FORMAS_PAGAMENTO) => void
  pagamentoPix: (IPixRequest: IPixRequest) => void
  salvarDadosPessoais: (IDadosPessoaisRequest: IDadosPessoais) => Promise<any>
  pagamentoBoleto: (IBoletoRequest: IBoletoRequest) => void
  pagamentoCartaoCredito: (form: ICartaoForm) => void
  copyTextToClipboard: (texto: string) => void
  copiarNumeroBoleto: (boleto?: PagamentoBoletoResponse) => void
  limparFracionado: () => void
  reiniciarContext: () => void
  obterParcelas: (valor) => Promise<boolean>
}

export interface IPixRequest {
  integral: boolean
  cidade?: string
  cep?: string
  endereco?: string
  estado?: string
  valor?: number
  //Customer
  cpf?: string
  email?: string
  nome?: string
  telefone?: string
}
export interface IBoletoRequest {
  integral: boolean
  cidade?: string
  cep?: string
  endereco?: string
  estado?: string
  valor?: number
  //Customer
  cpf?: string
  email?: string
  nome?: string
  telefone?: string
}

const PagamentoContext = createContext<IPagamentoContext>({} as any)

export default PagamentoContext
