import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  IconButton,
  MenuItem
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import {
  Alert,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator
} from '@material-ui/lab'
import React, { ReactInstance, useEffect, useRef, useState } from 'react'
import { FiClock, FiInfo } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import {
  aprovadoPeloPaciente,
  enviarParaPaciente,
  obterDadosPropostaPorIdOperacional,
  obterHistoricoPropostas,
  marcarPropostaVencedora,
  aprovarPendencia,
  recusarPendencia
} from 'src/api/proposta/index'
import DetalhesPropostaMobile from 'src/components/propostas/DetalhesPropostaMobile'
import Button from 'src/components/ui/Button'
import StatusProposta from 'src/core/consts/statusProposta'
import { formatarDataHoraLocal } from 'src/core/formatacoes/formatarData'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { useQuery } from 'src/hooks/useQuery'
import {
  DadosPropostaHistorico,
  IMedico,
  IPaciente,
  IPacoteOrcado,
  ObjetoDescricaoGenerico,
  ResumoPropostaResponse
} from 'src/types'
import Swal from 'sweetalert2'
import ImpressaoProposta, {
  DadosImpressao,
  IOPMESelecionado
} from './impressaoProposta'
import HeaderSolicitacao from 'src/components/solicitacao/HeaderSolicitacao'
import { RiArrowLeftLine } from 'react-icons/ri'
import { SynRoutes } from '../../../../core/consts/synRoutes'
import formatarCNPJ from 'src/core/formatacoes/formatarCNPJ'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import Ubox from 'src/components/ui/Ubox'
import DrawerAlterarStatusProposta from '../proposta/drawerAlterarStatusProposta'
import ModalLiberarAcesso from 'src/components/modals/ModalSenhaMaster'
import informarcaoAlert from 'src/core/informacoes/informacaoAlert'
import ModalGenerico from 'src/components/modals/ModalGenerico'
import TextField from 'src/components/ui/TextField'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from 'src/components/yupCustom'
import { toast } from 'react-toastify'

const btnRed = {
  border: '1px solid red',
  color: 'red'
}

const contestacaoSchema = yup.object().shape({
  contestacao: yup.string().required().min(4).label('Motivação')
})

const useStyles = makeStyles(theme => ({
  '.aprovadoDot': {
    backgroundColor: StatusProposta.APROVADA_PELO_MEDICO.Color
  },
  '.contestadoDot': {
    backgroundColor: StatusProposta.RECUSADA_PELO_MEDICO.Color
  },
  '.enviadoDot': {
    backgroundColor: StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Color
  },
  '.aprovadoPacienteDot': {
    backgroundColor: StatusProposta.APROVADA_PELO_PACIENTE.Color
  },
  '.aguardandoPacienteDot': {
    backgroundColor: StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Color
  },
  headerDivider: {
    margin: '10px 0 30px 0'
  },
  moreIcon: {
    position: 'absolute',
    right: 20,
    transform: 'rotateZ(-90deg) translateY(50%)'
  },
  moreIconCard: { position: 'absolute', right: -11 },
  containerCard: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 1300px)': {
      flexDirection: 'column'
    }
  },
  containerCardDetail: {
    minWidth: '540px',
    '@media (max-width: 1300px)': {
      minWidth: '700px'
    },
    '@media (max-width: 1005px)': {
      minWidth: '640px'
    }
  },
  historyCard: {
    cursor: 'pointer',
    background: theme.palette.grey[50],
    boxShadow: 'none',
    border: '1px solid #efefef',
    minWidth: '287px',
    position: 'relative',
    '@media (max-width: 1300px)': {
      minWidth: '665px'
    },
    '@media (max-width: 1005px)': {
      minWidth: '600px'
    },
    '@media (max-width: 955px)': {
      minWidth: '700px'
    },
    '@media (max-width: 760px)': {
      minWidth: '320px'
    }
  },
  boxPropostaDetalhe: {
    minHeight: 400,
    background: theme.palette.grey[50],
    boxShadow: 'none',
    border: '1px solid #efefef'
  },
  hospitalCard: {
    color: '#465a81',
    padding: '20px',
    textAlign: 'center',
    border: '1px solid #465b82',
    margin: '20px 0'
  },
  hositalCardText: {
    margin: '1px 0'
  },
  cardDivider: {
    margin: '10px 0'
  },
  OPMECard: {
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
    border: '1px solid #efefef',
    boxShadow: 'none',
    background: theme.palette.background.paper
  },
  timeline: {
    '&& .MuiTimelineItem-missingOppositeContent:before': {
      flex: 'none'
    },
    '&& .MuiTimelineDot-root': {
      marginTop: 0,
      marginBottom: 0
    },
    '&& .MuiTimelineConnector-root': {
      backgroundColor: '#e4e4e4'
    }
  },
  botao: {
    borderColor: theme.palette.common.white,
    marginBottom: '5px',

    fontWeight: 600,
    '& text': {
      color: '#137061'
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}))

export default function HistoricoPropostas() {
  const styles = useStyles()
  const theme = useTheme()
  const query = useQuery()
  const history = useHistory()
  const { user, perfilAtual } = useAuth()

  const ehComercial =
    perfilAtual === SynPerfis.COMERCIAL_B2B ||
    perfilAtual === SynPerfis.COMERCIAL_MEDICOS ||
    perfilAtual === SynPerfis.COMERCIAL_EXPANSAO

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const [showDetalhesMobile, setShowDetalhesMobile] = useState(false)

  const [loading, setLoading] = useState(true)
  const [card, setCard] = useState<number | undefined>(0)

  const [propostaEditar, setpropostaEditar] = useState<DadosPropostaHistorico>(
    null
  )

  const [
    propostaSelecionada,
    setPropostaSelecionada
  ] = useState<ResumoPropostaResponse>(null)
  const [loadingProposta, setLoadingProposta] = useState(true)
  const [loadingEnviar, setLoadingEnviar] = useState(false)

  const [medico, setMedico] = useState<IMedico>()
  const [paciente, setPaciente] = useState<IPaciente>()
  const [listaPropostas, setListaProposta] = useState<
    DadosPropostaHistorico[]
  >()
  const [pacoteProcedimento, setPacoteProcedimento] = useState<IPacoteOrcado>()
  const [mostrarAlterarStatus, setMostrarAlterarStatus] = useState(false)
  const [
    ehAguardandoRespostaPaciente,
    setehAguardandoRespostaPaciente
  ] = useState(false)
  const solicitacaoId = query.get('id')
  const [showModal, setShowModal] = useState(false)
  const [
    podeDesabilitarItem,
    setPodeDesabilitarItem
  ] = useState<DadosPropostaHistorico>()
  const [podeDesabilitarBoolean, setPodeDesabilitarBoolean] = useState<boolean>(
    false
  )
  const [openDesabilitar, setOpenDesabilitar] = useState(false)

  const [showModalEstornar, setShowModalEstornar] = useState(false)
  const [podeEstornarItem, setPodeEstornarItem] = useState<any>()
  const [podeEstornarBoolean, setPodeEstornarBoolean] = useState<boolean>(false)
  const [showContestacao, setShowContestacao] = useState(false)
  const [aprovacaoLoading, setAprovacaoLoading] = useState(false)
  const [contestacaoLoading, setContestacaoLoading] = useState(false)

  useEffect(() => {
    obterPropostas(solicitacaoId)
  }, [])

  const [dadosImpressao, setDadosImpressao] = useState<
    DadosImpressao | undefined
  >()
  const impressaoRef = useRef<HTMLElement>()
  const handlePrint = useReactToPrint({
    content: () => impressaoRef.current as ReactInstance
  })

  async function handleMostrarAlterarStatus(podeEstornar: boolean) {
    const estornarItem = podeEstornarItem || podeDesabilitarItem
    const estornarBoolean = podeEstornarBoolean

    if (
      estornarItem.possuiPagamento === true &&
      podeDesabilitarBoolean === false
    ) {
      let swalResult = await Swal.fire({
        title: 'Atenção',
        icon: 'warning',
        text:
          'Essa proposta possui um ou mais pagamentos vinculados e não pode ser encerrada'
      })
      if (swalResult.isDismissed) return
    }

    if (estornarItem.possuiPagamento !== true) {
      if (podeEstornar === true && podeDesabilitarBoolean === false) {
        setMostrarAlterarStatus(true)
        setehAguardandoRespostaPaciente(estornarBoolean)
        setpropostaEditar(estornarItem)
      }
      if (podeEstornar === true && podeDesabilitarBoolean === true) {
        setOpenDesabilitar(true)
        setpropostaEditar(estornarItem)
      }
    }
  }

  function setStatusColor(status: number) {
    if (status === 2) return StatusProposta.APROVADA_PELO_MEDICO.Color
    if (status === 3) return StatusProposta.RECUSADA_PELO_MEDICO.Color
    if (status === 1) return StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Color
    if (status === 4) return StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Color
    if (status === 5) return StatusProposta.APROVADA_PELO_PACIENTE.Color
    if (status === 6) return StatusProposta.VENCEDORA.Color
    if (status === 7) return StatusProposta.RECUSADA_PELO_PACIENTE.Color
    if (status === 8) return StatusProposta.ENCERRADA.Color
    if (status === 9) return StatusProposta.DESATIVADA.Color
    if (status === 10) return StatusProposta.PENDENTE_REVISAO.Color
    if (status === 11) return StatusProposta.RECUSADA_PELO_HOSPITAL.Color
  }

  function renderPalavraStatus(status: number) {
    for (const key in StatusProposta) {
      let statusProposta = StatusProposta[key]
      if (statusProposta.Id === status) return statusProposta.Descricao
    }
  }

  function obterPropostas(id) {
    obterHistoricoPropostas(id)
      .then(response => {
        setMedico(response.data.medico)
        setPaciente(response.data.paciente)
        setListaProposta(response.data.listaPropostas)
        setPacoteProcedimento(response.data.pacoteProcedimento)
        handleSelecionarProposta(0, response.data.listaPropostas[0])
        setLoading(false)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        history.goBack()
      })
  }

  async function handleEnviarParaPaciente() {
    setLoadingEnviar(true)
    try {
      await enviarParaPaciente({
        propostaId: propostaSelecionada.id,
        perfilAtual: perfilAtual
      })
      obterPropostas(solicitacaoId)
      Swal.fire(
        'Proposta marcada como enviada para paciente com sucesso!',
        '',
        'success'
      )
    } catch (error) {
      showNotificationErrorAPI(error)
    }
    setLoadingEnviar(false)
  }

  async function handleAprovadoPeloPaciente() {
    setLoadingEnviar(true)
    try {
      await aprovadoPeloPaciente({
        propostaId: propostaSelecionada.id,
        perfilAtual: perfilAtual
      })
      obterPropostas(solicitacaoId)
      Swal.fire(
        'Proposta marcada como aprovada pelo paciente com sucesso!',
        '',
        'success'
      )
    } catch (error) {
      showNotificationErrorAPI(error)
    }
    setLoadingEnviar(false)
  }

  function handleSelecionarProposta(index, proposta) {
    setLoadingProposta(true)
    setCard(index)

    obterDadosPropostaPorIdOperacional(proposta.id)
      .then(response => {
        const dados = response.data

        setPropostaSelecionada(dados)
        setLoadingProposta(false)
        const impressao: DadosImpressao = {
          medico: dados.medicoNome,
          paciente: dados.paciente,
          listaOPME: dados.listaOPME as IOPMESelecionado[],
          localAtendimento: dados.localAtendimento,
          pacoteProcedimento: dados.pacoteProcedimento,
          valorTotal: dados.valorTotal,
          examesLaboratorio: dados.examesLaboratorio,
          temAnestesia: dados.temAnestesia,
          temPreAnestesica: dados.temPreAnestesica,
          temRiscoCirurgico: dados.temRiscoCirurgico,
          temExames: dados.temExames,
          temReservaSangue: dados.temReservaSangue,
          propostaSemLocalAtendimento: dados.propostaSemLocalAtendimento,
          propostaSemHonorarioMedico: dados.propostaSemHonorarioMedico,
          propostaSemOpme: dados.propostaSemOpme,
          codigoSolicitacao: dados.codigoSolicitacao,
          codigoProposta: dados.codigoProposta,
          informacaoExame: dados.informacaoExame,
          temTaxaSyn: dados.temTaxaSyn,
          criado: dados.criado,
          valorAnestesia: dados.valorAnestesia,
          valorTotalOPME: dados.valorTotalOPME,
          valorBancoSangue: dados.valorBancoSangue,
          valorReservaSangue: dados.valorReservaSangue,
          valorComissaoSyn: dados.valorComissaoSyn || dados.valorComissaoSYN,
          temBolsaSangue: dados.temBolsaSangue,
          valorSala: dados.valorSala + dados.valorOPME,
          honorarioMedico: dados.honorarioMedico,
          status: dados.status,
          descricaoAnestesia: dados.descricaoAnestesia,
          descricaoMaterial: dados.descricaoMaterial,
          descricaoEquipamento: dados.descricaoEquipamento,
          descricaoDiarias: dados.descricaoDiarias,
          tempoCirurgico: dados.tempoCirurgico,
          observacaoExame: dados.observacaoExame,
          observacaoPacotePaciente: dados.observacaoPacotePaciente,
          observacaoSangue: dados.observacaoSangue,
          statusSolicitacao: dados.statusSolicitacao,
          observacaoHospital: dados.observacaoHospital,
          propostaCentroCirurgicoAmbulatorial:
            dados.propostaCentroCirurgicoAmbulatorial,
          propostaFinalDeSemana: dados.propostaFinalDeSemana
        }

        setDadosImpressao(impressao)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoadingProposta(false)
      })
  }

  async function handlePropostaVencedora() {
    setLoadingEnviar(true)
    const body = {
      propostaId: propostaSelecionada.id
    }
    try {
      await marcarPropostaVencedora(body)
      obterPropostas(solicitacaoId)
      Swal.fire('Proposta marcada como vencedora com sucesso!', '', 'success')
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoadingEnviar(false)
  }

  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  function financiamento(propostaId) {
    window.open(`
      ${SynRoutes.financiamentoStep}?p=${propostaId}`)
  }

  function dadosModalDisabled() {
    return {
      copy: 'A funcionalidade de desativar exige autorização master',
      titulo: 'Desativar Proposta',
      open: showModal,
      handleClose: () => setShowModal(false),
      onConfirm: ehConfirm => handleMostrarAlterarStatus(ehConfirm)
    }
  }

  function handleDisabled(item: any, estornar: boolean) {
    setPodeDesabilitarItem(item)
    setPodeDesabilitarBoolean(estornar)
    setShowModal(true)
  }

  function handleEstornar(item: any, estornar: boolean) {
    setPodeEstornarItem(item)
    setPodeEstornarBoolean(estornar)
    setShowModalEstornar(true)
  }

  function dadosModalEstornar() {
    return {
      copy: 'A funcionalidade de estornar exige autorização master',
      titulo: 'Estornar Proposta',
      open: showModalEstornar,
      handleClose: () => setShowModalEstornar(false),
      onConfirm: ehConfirm => handleMostrarAlterarStatus(ehConfirm)
    }
  }

  function handleCloseDrawer(estornado: boolean) {
    if (estornado === true) {
      location.reload()
    }
    setMostrarAlterarStatus(false)
  }

  function gerarDescricao(item) {
    let partes = []
    if (!!item.diasUteis) {
      partes.push('em qualquer dia da semana')
    }
    if (!!item.finalDeSemana) {
      partes.push('nos finais de semana')
    }
    if (!!item.centroCirurgicoAmbulatorial) {
      partes.push('no centro cirúrgico ambulatorial')
    }
    if (
      !item.diasUteis &&
      !item.finalDeSemana &&
      !item.centroCirurgicoAmbulatorial
    ) {
      partes.push('em qualquer dia e horário')
    }

    let descricao = 'Para realização '
    if (partes.length === 1) {
      descricao += partes[0] + '.'
    } else if (partes.length === 2) {
      descricao += partes.join(' no ') + '.'
    }
    return descricao
  }

  async function submitAprovacao() {
    setAprovacaoLoading(true)
    try {
      await aprovarPendencia(solicitacaoId)
      await Swal.fire({
        title: 'Sucesso!',
        text: 'Suas propostas foram aprovadas.',
        icon: 'success',
        confirmButtonText: 'Continuar',
        willClose: () => {
          setAprovacaoLoading(false)
          history.goBack()
        }
      })
    } catch (err) {
      showNotificationErrorAPI(err)
      setAprovacaoLoading(false)
    }
  }

  async function submitContestacao() {
    setContestacaoLoading(true)
    try {
      await recusarPendencia(solicitacaoId)
      Swal.fire({
        title: 'Obrigado!',
        text: 'Suas propostas foram recusadas.',
        icon: 'info',
        confirmButtonText: 'Continuar',
        willClose: () => {
          setContestacaoLoading(false)
          history.goBack()
        }
      })
    } catch (err) {
      showNotificationErrorAPI(err)
      setContestacaoLoading(false)
      setShowContestacao(false)
    }
  }

  return (
    <Box>
      {showModal && (
        <ModalLiberarAcesso
          isDelete={true}
          infoText={
            <>
              Tem certeza que deseja desativar a proposta de código:{' '}
              <strong>{podeDesabilitarItem.codigo}</strong>?
            </>
          }
          cancelButtonText="Cancelar"
          confirmButtonText="Desativar"
          {...dadosModalDisabled()}
        />
      )}

      {showModalEstornar && (
        <ModalLiberarAcesso
          isDelete={true}
          infoText={<>Tem certeza que deseja estornar a proposta?</>}
          cancelButtonText="Cancelar"
          confirmButtonText="Estornar"
          {...dadosModalEstornar()}
        />
      )}
      {showDetalhesMobile && ehXS ? (
        <DetalhesPropostaMobile
          handlePrint={handlePrint}
          handleEnviarParaPaciente={handleEnviarParaPaciente}
          handleAprovadoPeloPaciente={handleAprovadoPeloPaciente}
          loadingEnviar={loadingEnviar}
          handleClose={() => setShowDetalhesMobile(false)}
          propostaSelecionada={propostaSelecionada}
          handlePropostaVencedora={handlePropostaVencedora}
          valorExames={propostaSelecionada.valorExamesPreOperatorios}
        />
      ) : (
        <>
          <Link
            component="button"
            color="primary"
            onClick={() =>
              history.push(perfilAtual === SynPerfis.OPERACIONAL ? SynRoutes.listagemOrcamentosOperacional : SynRoutes.listagemSolicitacoesPendencias)
            }
            style={{ marginBottom: '20px' }}
          >
            <RiArrowLeftLine />
            <Typography
              style={{ marginLeft: 10 }}
              variant="subtitle1"
              component="span"
            >
              Voltar
            </Typography>
          </Link>
          <HeaderSolicitacao
            proposta
            paciente={paciente}
            pacoteProcedimento={pacoteProcedimento}
            medicoNome={medico.nome}
            comorbidades={paciente.comorbidades as ObjetoDescricaoGenerico[]}
            obsComorbidades={paciente.observacaoComorbidades}
          />

          <Grid container className={styles.containerCard}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">Propostas</Typography>
              <Timeline
                className={styles.timeline}
                align="left"
                style={{ transform: 'translateX(-40px)' }}
              >
                <Grid item>
                  {listaPropostas?.map((p, i) => {
                    return (
                      <TimelineItem key={p.codigo}>
                        <TimelineSeparator>
                          <TimelineDot
                            style={{
                              backgroundColor: setStatusColor(p.status)
                            }}
                          />
                          {i !== listaPropostas.length - 1 && (
                            <TimelineConnector />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Box
                            mb={2}
                            component={Card}
                            className={styles.historyCard}
                            style={
                              card === i
                                ? {
                                  border: '2px solid #000'
                                }
                                : null
                            }
                          >
                            {(p.status ===
                              StatusProposta.RECUSADA_PELO_PACIENTE.Id ||
                              p.status ===
                              StatusProposta.APROVADA_PELO_PACIENTE.Id ||
                              p.status === StatusProposta.VENCEDORA.Id ||
                              p.status ===
                              StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Id ||
                              p.status ===
                              StatusProposta.AGUARDANDO_APROVACAO_PACIENTE
                                .Id ||
                              p.status === StatusProposta.ENCERRADA.Id ||
                              p.status === StatusProposta.DESATIVADA.Id) &&
                              perfilAtual === SynPerfis.OPERACIONAL && (
                                <Box>
                                  <Typography>
                                    <PopupState
                                      variant="popover"
                                      popupId="demo-popup-menu"
                                    >
                                      {popupState => (
                                        <React.Fragment>
                                          {p.status !== 9 && (
                                            <IconButton
                                              aria-controls="simple-menu"
                                              aria-haspopup="true"
                                              aria-label="mais"
                                              className={styles.moreIconCard}
                                              {...bindTrigger(popupState)}
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          )}

                                          <Menu
                                            anchorOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right'
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right'
                                            }}
                                            {...bindMenu(popupState)}
                                          >
                                            {(p.status ===
                                              StatusProposta
                                                .RECUSADA_PELO_PACIENTE.Id ||
                                              p.status ===
                                              StatusProposta
                                                .APROVADA_PELO_PACIENTE.Id ||
                                              p.status ===
                                              StatusProposta.VENCEDORA.Id ||
                                              p.status ===
                                              StatusProposta.ENCERRADA
                                                .Id) && (
                                                <MenuItem
                                                  onClick={() => {
                                                    handleEstornar(p, false)
                                                    popupState.close()
                                                  }}
                                                >
                                                  Estornar proposta
                                                </MenuItem>
                                              )}
                                            <MenuItem
                                              onClick={() => {
                                                financiamento(p.id)
                                                popupState.close()
                                              }}
                                            >
                                              Solicitar financiamento
                                            </MenuItem>
                                            {(p.status ===
                                              StatusProposta
                                                .AGUARDANDO_APROVACAO_MEDICO
                                                .Id ||
                                              p.status ===
                                              StatusProposta
                                                .AGUARDANDO_APROVACAO_PACIENTE
                                                .Id) && (
                                                <MenuItem
                                                  onClick={() => {
                                                    handleDisabled(p, true)
                                                    popupState.close()
                                                  }}
                                                >
                                                  Desativar proposta
                                                </MenuItem>
                                              )}
                                            {p.status ===
                                              StatusProposta
                                                .AGUARDANDO_APROVACAO_PACIENTE
                                                .Id && (
                                                <MenuItem
                                                  onClick={() => {
                                                    handleEstornar(p, true)
                                                    popupState.close()
                                                  }}
                                                >
                                                  Mudar status
                                                </MenuItem>
                                              )}
                                          </Menu>
                                        </React.Fragment>
                                      )}
                                    </PopupState>
                                  </Typography>
                                </Box>
                              )}
                            <Box
                              p={2}
                              onClick={() => {
                                handleSelecionarProposta(i, p)
                                setShowDetalhesMobile(true)
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    width: '100%',
                                    overflowX: 'hidden'
                                  }}
                                >
                                  <Ubox maxWidth="90%" mb={2}>
                                    <Typography
                                      variant="subtitle2"
                                      style={{
                                        whiteSpace: 'nowrap',
                                        textTransform: 'uppercase'
                                      }}
                                      color={
                                        p.status !== 9
                                          ? 'primary'
                                          : 'textSecondary'
                                      }
                                    >
                                      <b>{p.internacao}</b>
                                    </Typography>
                                    <Ubox
                                      style={{
                                        background:
                                          p.status !== 9
                                            ? '#21ebc7'
                                            : '#b3b3b3',
                                        width: '60px',
                                        height: '4px',
                                        borderRadius: '4px'
                                      }}
                                      display="block"
                                    ></Ubox>
                                  </Ubox>
                                  {p?.status === 6 ? (
                                    <Chip
                                      variant="default"
                                      size="small"
                                      label={renderPalavraStatus(p.status)}
                                      style={{
                                        fontWeight: 600,
                                        color: '#fff',
                                        backgroundColor: setStatusColor(
                                          p.status
                                        )
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      variant="outlined"
                                      size="small"
                                      label={renderPalavraStatus(p.status)}
                                      style={{
                                        fontWeight: 600,
                                        color: setStatusColor(p.status),
                                        borderColor: setStatusColor(p.status)
                                      }}
                                    />
                                  )}
                                  <Box mt={1} style={{ paddingRight: '10px' }}>
                                    <Typography
                                      variant="body2"
                                      style={{
                                        whiteSpace: 'nowrap',
                                        fontSize: '16px',
                                        color:
                                          p.status !== 9 ? '#000000' : '#7a7a7a'
                                      }}
                                    >
                                      <b>{p.localAtendimento}</b>
                                    </Typography>
                                  </Box>
                                  {propostaSelecionada?.status !==
                                    StatusProposta.PENDENTE_REVISAO.Id && (
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          whiteSpace: 'nowrap',
                                          color:
                                            p.status !== 9 ? '#000000' : '#7a7a7a'
                                        }}
                                      >
                                        <b>
                                          <small>Valor total:</small>
                                        </b>{' '}
                                        {formatarDinheiro(p.valorTotal)}
                                      </Typography>
                                    )}
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      whiteSpace: 'nowrap',
                                      color:
                                        p.status !== 9 ? '#000000' : '#7a7a7a'
                                    }}
                                  >
                                    <b>
                                      <small>Código:</small>
                                    </b>{' '}
                                    {p.codigo}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    <b>
                                      <small>
                                        {!!p?.centroCirurgicoAmbulatorial
                                          ? 'Centro cirúrgico ambulatorial'
                                          : 'Centro cirúrgico'}
                                      </small>
                                    </b>
                                  </Typography>

                                  <Ubox
                                    render={
                                      !!p.centroCirurgicoAmbulatorial ||
                                      !!p.finalDeSemana ||
                                      !!p.diasUteis ||
                                      !p.finalDeSemana
                                    }
                                  >
                                    <Alert
                                      severity="warning"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        padding: '8px'
                                      }}
                                      iconMapping={{
                                        warning: (
                                          <FiInfo style={{ color: 'orange' }} />
                                        )
                                      }}
                                    >
                                      {gerarDescricao(p)}
                                      <br />
                                      {!!p.diasUteis &&
                                        'Segunda a sábado até as 12h.'}
                                      {!!p.finalDeSemana &&
                                        'Sábado a partir de 12h até domingo 23h59 e feriados.'}
                                    </Alert>
                                  </Ubox>

                                  {p.possuiPagamento === true && (
                                    <Alert severity="warning">
                                      Proposta possui um ou mais pagamentos!
                                    </Alert>
                                  )}
                                  <Typography variant="body2">
                                    <Ubox
                                      mt={2}
                                      component="small"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="end"
                                      mr={1}
                                      style={{
                                        color:
                                          p.status !== 9 ? '#000000' : '#7a7a7a'
                                      }}
                                    >
                                      <Box
                                        component={FiClock}
                                        style={{ marginRight: '4px' }}
                                      />
                                      <b>
                                        <i>
                                          {formatarDataHoraLocal(p.dataEnvio)}
                                        </i>
                                      </b>
                                    </Ubox>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  })}
                </Grid>
              </Timeline>
            </Grid>
            {!ehXS && propostaSelecionada && (
              <Grid item xs={12} sm={8} className={styles.containerCardDetail}>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="h6">
                    Detalhes da proposta {propostaSelecionada?.codigo}
                  </Typography>
                  <Ubox render={!ehComercial && propostaSelecionada?.status !==
                    StatusProposta.PENDENTE_REVISAO.Id} display="flex">
                    <Ubox
                      render={
                        !loadingProposta &&
                        ![
                          StatusProposta.ENCERRADA.Id,
                          StatusProposta.RECUSADA_PELO_PACIENTE.Id,
                          StatusProposta.RECUSADA_PELO_MEDICO.Id
                        ].includes(propostaSelecionada?.status)
                      }
                      mr={1}
                    >
                      <Button variant="outlined" onClick={() => handlePrint()}>
                        Visualizar
                      </Button>
                    </Ubox>
                    {propostaSelecionada?.status ===
                      StatusProposta.APROVADA_PELO_MEDICO.Id && (
                        <Button
                          loading={loadingEnviar}
                          disabled={loadingEnviar}
                          onClick={handleEnviarParaPaciente}
                        >
                          Enviar ao paciente
                        </Button>
                      )}

                    {propostaSelecionada?.status ===
                      StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Id && (
                        <Button
                          loading={loadingEnviar}
                          disabled={loadingEnviar}
                          onClick={handleAprovadoPeloPaciente}
                        >
                          Aprovada pelo paciente
                        </Button>
                      )}

                    {propostaSelecionada?.status ===
                      StatusProposta.APROVADA_PELO_PACIENTE.Id && (
                        <Button
                          color="secondary"
                          loading={loadingEnviar}
                          disabled={loadingEnviar}
                          onClick={handlePropostaVencedora}
                        >
                          Proposta vencedora
                        </Button>
                      )}
                  </Ubox>
                </Grid>
                <Box
                  mt={2}
                  py={2}
                  px={2}
                  minHeight="400"
                  component={Card}
                  className={styles.boxPropostaDetalhe}
                >
                  <Box mt={4} hidden={!loadingProposta} textAlign="center">
                    <CircularProgress />
                    <div>Carregando...</div>
                  </Box>
                  {propostaSelecionada && (
                    <Box hidden={loadingProposta}>
                      <>
                        {propostaSelecionada.motivo && (
                          <Alert severity="error">
                            {propostaSelecionada.motivo}
                          </Alert>
                        )}

                        {propostaSelecionada?.motivoMudancaStatus && (
                          <Alert severity="info">
                            {propostaSelecionada?.motivoMudancaStatus}
                          </Alert>
                        )}

                        {propostaSelecionada.status ===
                          StatusProposta.APROVADA_PELO_MEDICO.Id && (
                            <Alert severity="success">
                              O médico aprovou a proposta.
                            </Alert>
                          )}
                        <Box width="100%" textAlign="center" mb={2}>
                          <Card className={styles.hospitalCard}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="h6"
                                  align="left"
                                  color={
                                    propostaSelecionada?.status === 9
                                      ? 'textSecondary'
                                      : 'primary'
                                  }
                                >
                                  <b>{propostaSelecionada?.localAtendimento}</b>
                                </Typography>
                                <Box>
                                  <Grid container>
                                    {!propostaSelecionada?.propostaSemLocalAtendimento && (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ padding: '5px' }}
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="flex-end"
                                        >
                                          <Grid item xs={12} sm={12}>
                                            <Typography
                                              variant="subtitle2"
                                              className={styles.hositalCardText}
                                              align="left"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              Valor hospital:
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            className={styles.hositalCardText}
                                          >
                                            <Typography
                                              align="left"
                                              variant="body2"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              {propostaSelecionada?.valorSala
                                                ? formatarDinheiro(
                                                  propostaSelecionada?.valorSala ||
                                                  0
                                                )
                                                : 'Não informado'}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )}

                                    {propostaSelecionada?.temAnestesia && (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ padding: '5px' }}
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="flex-end"
                                        >
                                          <Grid item xs={12} sm={12}>
                                            <Typography
                                              variant="subtitle2"
                                              className={styles.hositalCardText}
                                              align="left"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              {propostaSelecionada?.ehAnestesiaEquipeMedica
                                                ? 'Valor anestesia (equipe médica): '
                                                : 'Valor anestesia: '}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            className={styles.hositalCardText}
                                          >
                                            <Typography
                                              align="left"
                                              variant="body2"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              {propostaSelecionada?.valorAnestesia >=
                                                0
                                                ? formatarDinheiro(
                                                  propostaSelecionada?.valorAnestesia ||
                                                  0
                                                )
                                                : 'Não informado'}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )}

                                    {propostaSelecionada?.valorBancoSangue !==
                                      null && (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          style={{ padding: '5px' }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="flex-end"
                                          >
                                            <Grid item xs={12} sm={12}>
                                              <Typography
                                                variant="subtitle2"
                                                className={styles.hositalCardText}
                                                align="left"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                Valor bolsa de sangue:
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              className={styles.hositalCardText}
                                            >
                                              <Typography
                                                align="left"
                                                variant="body2"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                {propostaSelecionada?.valorBancoSangue >=
                                                  0
                                                  ? formatarDinheiro(
                                                    propostaSelecionada?.valorBancoSangue ||
                                                    0
                                                  )
                                                  : 'R$ 0,00'}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}

                                    {propostaSelecionada?.valorReservaSangue !==
                                      null && (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          style={{ padding: '5px' }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="flex-end"
                                          >
                                            <Grid item xs={12} sm={12}>
                                              <Typography
                                                variant="subtitle2"
                                                className={styles.hositalCardText}
                                                align="left"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                Valor reserva de sangue:
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              className={styles.hositalCardText}
                                            >
                                              <Typography
                                                align="left"
                                                variant="body2"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                {propostaSelecionada?.valorReservaSangue >=
                                                  0
                                                  ? formatarDinheiro(
                                                    propostaSelecionada?.valorReservaSangue ||
                                                    0
                                                  )
                                                  : 'R$ 0,00'}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}

                                    {propostaSelecionada?.valorPreAnestesica !==
                                      null && (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          style={{ padding: '5px' }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="flex-end"
                                          >
                                            <Grid item xs={12} sm={12}>
                                              <Typography
                                                variant="subtitle2"
                                                className={styles.hositalCardText}
                                                align="left"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                Valor avaliação pré-anestésica:
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              className={styles.hositalCardText}
                                            >
                                              <Typography
                                                align="left"
                                                variant="body2"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                {propostaSelecionada?.valorPreAnestesica >=
                                                  0
                                                  ? formatarDinheiro(
                                                    propostaSelecionada?.valorPreAnestesica ||
                                                    0
                                                  )
                                                  : 'R$ 0,00'}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}

                                    {propostaSelecionada?.valorRiscoCirurgico !==
                                      null && (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          style={{ padding: '5px' }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="flex-end"
                                          >
                                            <Grid item xs={12} sm={12}>
                                              <Typography
                                                variant="subtitle2"
                                                className={styles.hositalCardText}
                                                align="left"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                Valor avaliação risco cirúrgico:
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              className={styles.hositalCardText}
                                            >
                                              <Typography
                                                align="left"
                                                variant="body2"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                {propostaSelecionada?.valorRiscoCirurgico >=
                                                  0
                                                  ? formatarDinheiro(
                                                    propostaSelecionada?.valorRiscoCirurgico ||
                                                    0
                                                  )
                                                  : 'R$ 0,00'}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}
                                    {!propostaSelecionada?.propostaSemOpme && propostaSelecionada?.status !==
                                      StatusProposta.PENDENTE_REVISAO.Id && (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          style={{ padding: '5px' }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="flex-end"
                                          >
                                            <Grid item xs={12} sm={12}>
                                              <Typography
                                                variant="subtitle2"
                                                className={styles.hositalCardText}
                                                align="left"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                Total OPME:
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                              <Typography
                                                align="left"
                                                variant="body2"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                {formatarDinheiro(
                                                  propostaSelecionada?.valorTotalOPME
                                                ) || ' R$ 0,00'}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}
                                    {!propostaSelecionada?.propostaSemLocalAtendimento && (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ padding: '5px' }}
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="flex-end"
                                        >
                                          <Grid item xs={12} sm={12}>
                                            <Typography
                                              variant="subtitle2"
                                              className={styles.hositalCardText}
                                              align="left"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              Taxa OPME (
                                              {propostaSelecionada?.taxaOPME}
                                              %):
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={12}>
                                            <Typography
                                              align="left"
                                              variant="body2"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              {formatarDinheiro(
                                                propostaSelecionada?.valorOPME
                                              ) || ' R$ 0,00'}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {propostaSelecionada?.status !==
                                      StatusProposta.PENDENTE_REVISAO.Id && (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          style={{ padding: '5px' }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="flex-end"
                                          >
                                            <Grid item xs={12} sm={12}>
                                              <Typography
                                                variant="subtitle2"
                                                className={styles.hositalCardText}
                                                align="left"
                                                color={
                                                  propostaSelecionada?.status === 9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                Taxa de serviço (
                                                {propostaSelecionada?.taxaSYN.toFixed(
                                                  2
                                                )}
                                                %):
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                              <Typography
                                                align="left"
                                                variant="body2"
                                                color={
                                                  propostaSelecionada?.status === 9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                {formatarDinheiro(
                                                  propostaSelecionada?.valorComissaoSYN
                                                ) || ' R$ 0,00'}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}
                                    <Ubox
                                      render={
                                        propostaSelecionada?.valorExamesPreOperatorios >
                                        0
                                      }
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        style={{ padding: '5px' }}
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="flex-end"
                                        >
                                          <Grid item xs={12} sm={12}>
                                            <Typography
                                              variant="subtitle2"
                                              className={styles.hositalCardText}
                                              align="left"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              Exames pré-operatórios:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={12}>
                                            <Typography
                                              align="left"
                                              variant="body2"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              {formatarDinheiro(
                                                propostaSelecionada?.valorExamesPreOperatorios
                                              ) || ' R$ 0,00'}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Ubox>

                                    {!propostaSelecionada?.propostaSemHonorarioMedico && propostaSelecionada?.status !==
                                      StatusProposta.PENDENTE_REVISAO.Id && (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          style={{ padding: '5px' }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="flex-end"
                                          >
                                            <Grid item xs={12} sm={12}>
                                              <Typography
                                                variant="subtitle2"
                                                className={styles.hositalCardText}
                                                align="left"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                Honorários equipe médica:
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                              <Typography
                                                align="left"
                                                variant="body2"
                                                color={
                                                  propostaSelecionada?.status ===
                                                    9
                                                    ? 'textSecondary'
                                                    : 'primary'
                                                }
                                              >
                                                {formatarDinheiro(
                                                  propostaSelecionada?.honorarioMedico
                                                ) || ' R$ 0,00'}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}
                                    <Ubox
                                      render={
                                        propostaSelecionada?.informacaoExame
                                          ?.length > 0
                                      }
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{ padding: '5px' }}
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="flex-end"
                                        >
                                          <Grid item xs={12} sm={12}>
                                            <Typography
                                              variant="subtitle2"
                                              className={styles.hositalCardText}
                                              align="left"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              Observação de exames:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={12}>
                                            <Typography
                                              align="left"
                                              variant="body2"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              {
                                                propostaSelecionada?.informacaoExame
                                              }
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Ubox>

                                    <Ubox
                                      render={
                                        !!propostaSelecionada.observacaoHospital
                                      }
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{ padding: '5px' }}
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="flex-end"
                                        >
                                          <Grid item xs={12} sm={12}>
                                            <Typography
                                              variant="subtitle2"
                                              className={styles.hositalCardText}
                                              align="left"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              Observação do hospital:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={12}>
                                            <Typography
                                              align="left"
                                              variant="body2"
                                              color={
                                                propostaSelecionada?.status ===
                                                  9
                                                  ? 'textSecondary'
                                                  : 'primary'
                                              }
                                            >
                                              {
                                                propostaSelecionada?.observacaoHospital
                                              }
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Ubox>
                                    {propostaSelecionada?.status !==
                                      StatusProposta.PENDENTE_REVISAO.Id && (
                                        <Grid item xs={12}>
                                          <Ubox
                                            render={
                                              !!propostaSelecionada.taxaPersonalizada
                                            }
                                            component={Alert}
                                            severity="info"
                                          >
                                            Taxa de serviço foi alterada por{' '}
                                            <b>
                                              {
                                                propostaSelecionada
                                                  ?.taxaPersonalizada?.usuario
                                              }
                                            </b>
                                          </Ubox>
                                          <Divider className={styles.cardDivider} />
                                          <Typography
                                            variant="h6"
                                            color={
                                              propostaSelecionada?.status === 9
                                                ? 'textSecondary'
                                                : 'primary'
                                            }
                                          >
                                            <strong>Valor total:</strong>{' '}
                                            {formatarDinheiro(
                                              propostaSelecionada?.valorTotal
                                            )}
                                          </Typography>
                                        </Grid>
                                      )}
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Card>
                        </Box>

                        <Ubox
                          style={{ marginBottom: '10px' }}
                          render={
                            !!propostaSelecionada.propostaCentroCirurgicoAmbulatorial ||
                            !!propostaSelecionada.propostaDiasUteis ||
                            !!propostaSelecionada.propostaFinalDeSemana ||
                            !propostaSelecionada.propostaFinalDeSemana
                          }
                        >
                          <Alert
                            severity="warning"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              padding: '8px'
                            }}
                            iconMapping={{
                              warning: <FiInfo style={{ color: 'orange' }} />
                            }}
                          >
                            {informarcaoAlert(propostaSelecionada)}
                            <br />
                            {/* {!!propostaSelecionada.propostaDiasUteis &&
                              'Segunda a sábado até as 12h.'} */}
                            {!!propostaSelecionada.propostaFinalDeSemana &&
                              'Sábado a partir de 12h até domingo 23h59 e feriados.'}
                          </Alert>
                        </Ubox>

                        {propostaSelecionada?.propostaSemLocalAtendimento && (
                          <Box width="100%" textAlign="left" my={2}>
                            <Box mt={2}>
                              <Alert severity="info">
                                <Typography variant="subtitle2">
                                  Proposta não contempla local de atendimento.
                                </Typography>
                              </Alert>
                            </Box>
                          </Box>
                        )}

                        {propostaSelecionada?.propostaSemHonorarioMedico && (
                          <Box width="100%" textAlign="left" my={2}>
                            <Box mt={2}>
                              <Alert severity="info">
                                <Typography variant="subtitle2">
                                  Proposta não contempla honorários da equipe
                                  médica.
                                </Typography>
                              </Alert>
                            </Box>
                          </Box>
                        )}
                        <Box width="100%" textAlign="left" my={2}>
                          <Box mt={2}>
                            <Alert severity="info">
                              <p style={{ marginTop: 0 }}>
                                Não abrangem OPME's, materiais, perneiras, manta
                                térmica e exames não previstos na proposta, além
                                de intervenções de outras especialidades,
                                reoperações, hemoterapia, nutrição especial,
                                quimioterápicos e terapias renais.{' '}
                              </p>
                              <p>
                                Cobranças adicionais incluem diárias extras de
                                enfermaria por R$ 1.600 e de apartamento por R$
                                1.800, além de diárias de UTI não programadas,
                                que serão cobradas como conta aberta.{' '}
                              </p>
                              <p>
                                O orçamento é válido para cirurgias sem
                                intercorrências e para pacientes sem alergia a
                                látex; quaisquer complicações serão cobradas
                                como conta aberta (direto com o hospital).
                              </p>
                            </Alert>
                          </Box>
                        </Box>
                        {propostaSelecionada?.listaOPME?.length === 0 ? (
                          <Box width="100%" textAlign="left" my={2}>
                            <Box mt={2}>
                              <Alert severity="info">
                                <Typography variant="subtitle2">
                                  Proposta não contempla itens OPME.
                                </Typography>
                              </Alert>
                            </Box>
                          </Box>
                        ) : (
                          <>
                            <Box width="100%" textAlign="left" my={2}>
                              <Box mt={2}>
                                <Typography variant="h6">Lista OPME</Typography>
                              </Box>
                            </Box>
                            {propostaSelecionada?.listaOPME?.map((o, index) => (
                              <Card className={styles.OPMECard} key={o.id}>
                                <Box mb={1}>
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                  >
                                    <Typography variant="body2">
                                      <small>
                                        <b>
                                          {`${o.nomeParceiro} - ${!!formatarCNPJ(o.cnpjParceiro)
                                            ? formatarCNPJ(o.cnpjParceiro)
                                            : o.cnpjParceiro
                                            }`}
                                        </b>
                                      </small>
                                    </Typography>
                                  </Grid>
                                  <Ubox
                                    mt={2}
                                    component={Grid}
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="flex-start"
                                  >
                                    <Typography
                                      variant="h6"
                                      color="secondary"
                                      style={{ lineHeight: 1.1 }}
                                    >
                                      <b>{o.nomeOPME}</b>
                                      <Ubox
                                        render={o?.quantidadeIncluso > 0}
                                        fontSize="13px"
                                        color="#c4ad30"
                                        fontWeight="bold"
                                      >
                                        {o.quantidadeIncluso} unid. incluso no
                                        valor hospital
                                      </Ubox>
                                    </Typography>
                                    <Typography variant="h6">
                                      <b>Qtd. {o.quantidade}</b>
                                    </Typography>
                                  </Ubox>
                                </Box>

                                <Ubox
                                  render={
                                    !!o.pacoteInteligenteId && !!o.valorUnitario
                                  }
                                  component={Grid}
                                  container
                                >
                                  <Grid item xs={6}>
                                    <Typography variant="body2">
                                      <strong>Valor un.:</strong>{' '}
                                      {formatarDinheiro(o.valorUnitario)}
                                    </Typography>
                                  </Grid>
                                </Ubox>
                                <Ubox
                                  render={!o.pacoteInteligenteId}
                                  component={Grid}
                                  container
                                >
                                  <Grid item xs={12} sm={6}>
                                    <Typography variant="body2">
                                      <strong>Fabricante:</strong>{' '}
                                      {!!o.fabricante
                                        ? o.fabricante
                                        : 'Não informado'}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={6}>
                                    <Typography variant="body2">
                                      <strong>Valor un.:</strong>{' '}
                                      {formatarDinheiro(o.valorUnitario)}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography variant="body2">
                                      <strong>Cód. ANVISA:</strong>{' '}
                                      {o.codAnvisa
                                        ? o.codAnvisa
                                        : 'Não informado'}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={6}>
                                    <Typography variant="body2">
                                      <strong>Referência:</strong>{' '}
                                      {o.referencia
                                        ? o.referencia
                                        : 'Não informado'}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Typography variant="body2">
                                      <strong>Descrição:</strong>{' '}
                                      {o.descricao
                                        ? o.descricao
                                        : 'Não informado'}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Typography variant="body2">
                                      <strong>Observação:</strong>{' '}
                                      {o.observacao
                                        ? o.observacao
                                        : 'Não informado'}
                                    </Typography>
                                  </Grid>
                                </Ubox>
                              </Card>
                            ))}
                          </>
                        )}

                        {propostaSelecionada.examesLaboratorio?.exames
                          ?.length !== 0 && (
                            <Box
                              width="100%"
                              textAlign="left"
                              my={2}
                              style={{ borderTop: '1px solid #c4c4c4' }}
                            >
                              <Box mt={2}>
                                <Typography variant="h6">
                                  Lista de exames pré-operatórios
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '10px'
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    {
                                      propostaSelecionada.examesLaboratorio
                                        ?.laboratorioNome
                                    }
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    <strong>Total exames:</strong>{' '}
                                    {formatarDinheiro(
                                      propostaSelecionada.valorExamesPreOperatorios
                                    )}
                                  </Typography>
                                </div>
                              </Box>

                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell>Valor</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {propostaSelecionada.examesLaboratorio.exames?.map(
                                    item => {
                                      return (
                                        <TableRow key={item.id}>
                                          <TableCell>{item.codigo}</TableCell>
                                          <TableCell>{item.descricao}</TableCell>
                                          <TableCell>
                                            {formatarDinheiro(item.valor)}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          )}

                        {propostaSelecionada?.status ===
                          StatusProposta.PENDENTE_REVISAO.Id && (
                            <>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    style={{ ...btnRed }}
                                    color="default"
                                    variant="outlined"
                                    fullWidth
                                    loading={contestacaoLoading}
                                    disabled={contestacaoLoading}
                                    onClick={() => submitContestacao()}

                                  >
                                    Recusar valores
                                  </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    loading={aprovacaoLoading}
                                    disabled={aprovacaoLoading}
                                    onClick={() => submitAprovacao()}
                                  >
                                    Aprovar valores
                                  </Button>
                                </Grid>
                              </Grid>
                              {/* <ModalGenerico
                                title="Por favor, conte-nos o motivo por ter recusado"
                                open={showContestacao}
                                onClose={() => setShowContestacao(false)}
                                actions={
                                  <>
                                    <Button
                                      color="default"
                                      onClick={() => {
                                        setShowContestacao(false)
                                      }}
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                      onClick={handleSubmit(submitContestacao)}
                                      loading={contestacaoLoading}
                                      disabled={contestacaoLoading}
                                    >
                                      Enviar
                                    </Button>
                                  </>
                                }
                              >
                                <Box>
                                  <TextField
                                    erro={errors}
                                    multiline
                                    rows={6}
                                    name="contestacao"
                                    label="Motivação"
                                    inputRef={register}
                                  />
                                </Box>
                              </ModalGenerico> */}
                            </>
                          )}
                      </>
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      )
      }

      <ImpressaoProposta ref={impressaoRef} dados={dadosImpressao} />

      {
        mostrarAlterarStatus === true && !!propostaEditar && (
          <DrawerAlterarStatusProposta
            proposta={propostaEditar}
            open={mostrarAlterarStatus}
            ehAguardandoRespostaPaciente={ehAguardandoRespostaPaciente}
            handleOnClose={estornado => handleCloseDrawer(estornado)}
            obterPropostas={() => obterPropostas(solicitacaoId)}
          />
        )
      }

      {
        openDesabilitar === true && !!propostaEditar && (
          <DrawerAlterarStatusProposta
            proposta={propostaEditar}
            open={openDesabilitar}
            handleOnClose={estornado => handleCloseDrawer(estornado)}
            obterPropostas={() => obterPropostas(solicitacaoId)}
            isDisabled={true}
          />
        )
      }
    </Box >
  )
}
