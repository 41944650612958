import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  PopperProps,
  StandardProps,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete as AutocompleteMU } from '@material-ui/lab'
import {
  AutocompleteClassKey,
  AutocompleteGetTagProps,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState
} from '@material-ui/lab/Autocomplete'
import { createFilterOptions } from '@material-ui/lab/useAutocomplete'
import React, { useState, useContext, useEffect } from 'react'
import { FiPlus } from 'react-icons/fi'
import { IAny } from 'src/types'
import { Button } from './Button'
import { TextFieldSimpleCustom } from './TextField'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { MdArrowBack } from 'react-icons/md'
import { AutocompleteMobile } from './AutocompleteMobile'
import { MobileContext } from 'src/contexts/Mobile'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  btnAdd: {
    position: 'absolute',
    bottom: -50,
    left: 0,
    backgroundColor: '#fff',
    width: '100%',
    padding: '10px 20px 10px 20px',
    boxShadow: '0px 1px 1px   #777',
    borderTop: 'none'
  },
  btnPronto: {
    bottom: -68
  },
  [theme.breakpoints.down('xs')]: {
    focusAutocomplete: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
      background: theme.palette.background.paper,
      zIndex: 15,
      '&.ehIOS': {
        // position: 'absolute'
        position: 'fixed'
      },
      '& .autocomplete-box': {
        padding: '10px 16px 40px'
      },
      '& .MuiAutocomplete-root': {
        position: 'relative',
        // top: 0,
        // left: 0,
        height: '100vh'
      }
      // '& .MuiAutocomplete-popper': {
      //   width: '100vw',
      //   height: '58px',
      //   zIndex: 16,
      //   background: theme.palette.grey[200]
      // },
      // '& .MuiAutocomplete-popper .MuiAutocomplete-listbox': {
      //   height: '58px'
      // }
    },
    footer: {
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100vw',
      height: '58px',
      zIndex: 16,
      background: theme.palette.grey[200]
    },
    popper: {
      background: 'blue',
      height: '500px',
      width: '100vw',
      border: '1px solid green'
    },
    noMargin: {
      margin: 0
    }
  }
}))

export interface AutoCompleteCustomProps
  extends IAutocompleteCustomProps<any, true, true, false> {
  label: string
  name: string
  erro: any
  showAddButton?: boolean
  addButtonProps?: AddButtonProps
  onClickAddButton?: (inputValue: string) => void
  value: any
  showCheck?: boolean
  hideChips?: boolean
  hideSelected?: boolean
  ehProcedimento?: boolean
  buscarPorTermo?: (event: React.ChangeEvent<HTMLInputElement>) => void
  limparTermoBusca?: () => void
  errorMessage?: string
}

const filter = createFilterOptions<any>()
interface AddButtonProps {
  text: string
}
// let ehIOS = false
export const Autocomplete: React.FC<AutoCompleteCustomProps> = ({
  label,
  name,
  erro,
  onChange,
  showCheck,
  hideChips,
  hideSelected,
  showAddButton,
  addButtonProps,
  onClickAddButton,
  buscarPorTermo,
  errorMessage,
  ...rest
}) => {
  const classes = useStyles()

  const [aberto, setAberto] = useState(false)
  const [inputTexto, setInputTexto] = useState('')
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  // useEffect(() => {
  //   if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
  //     debugger
  //     console.log('This is an iOS device.')
  //     ehIOS = true
  //   } else {
  //     debugger
  //     console.log('This is not an iOS device!')
  //     ehIOS = false
  //   }
  // }, [])

  const { toggleAutocompleteMobile } = useContext(MobileContext)

  const getCheckbox = (option, selected) =>
    showCheck && (
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
      />
    )

  const deveFiltrarSelecionados = (array: any[]) => {
    return !!hideSelected && !!rest.multiple
      ? array.filter(
          (item, i) =>
            !(rest.value as any[]).find(v => {
              return item.id === v.id
            })
        )
      : array
  }
  //Se multiplos valores
  const deveRenderizarCheck =
    rest.multiple && showCheck
      ? {
          renderOption: (
            option,
            { selected }: AutocompleteRenderOptionState
          ) => (
            <React.Fragment>
              {getCheckbox(option, selected)}

              {option.descricao}
            </React.Fragment>
          )
        }
      : null

  const getChips: (
    values,
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode = (values, getTagProps: AutocompleteGetTagProps) => {
    return (
      !hideChips &&
      rest.value.map((option, index: number) => (
        <Chip
          key={index}
          variant="outlined"
          style={{ marginRight: 15 }}
          label={rest.getOptionLabel(option)}
          {...getTagProps({ index })}
        />
      ))
    )
  }

  const deveRenderizarChip =
    rest.multiple && Array.isArray(rest.value)
      ? {
          renderTags: (value, getTagProps) => getChips(value, getTagProps)
        }
      : null

  const deveRenderizarFiltro = !!hideSelected
    ? {
        filterOptions: (options, params) => {
          const filtered = deveFiltrarSelecionados(
            filter(options, params) as any[]
          )
          return filtered
        }
      }
    : null

  const deveRenderizarAddButton = !!showAddButton && {
    filterOptions: (options, params) => {
      const filtered = deveFiltrarSelecionados(filter(options, params) as any[])
      if (!filtered.length) {
        filtered.push({
          noOptions: true,
          inputText: `${params.inputValue}`
        })
      }

      filtered.push({
        btnAdd: true,
        inputText: `${params.inputValue}`
      })
      return filtered
    },
    renderOption: (option, { selected, inputValue }) => {
      const label = rest.getOptionLabel(option)
      const matches = match(label, inputValue)
      const parts = parse(label, matches)
      return (
        <React.Fragment>
          {!!option.noOptions ? (
            <Box
              onClick={e => {
                e.preventDefault()
              }}
            >
              <em>Nenhum resultado para "{option.inputText}"</em>
            </Box>
          ) : !!option.btnAdd ? (
            <Grid
              container
              className={`${classes.btnAdd} ${showCheck && classes.btnPronto}`}
              style={!addButtonProps ? { bottom: '-48px' } : undefined}
            >
              <Divider />

              {showCheck && (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    setAberto(false)
                  }}
                >
                  Pronto
                </Button>
              )}

              {!!addButtonProps && (
                <Button
                  fullWidth
                  variant="text"
                  onClick={() => {
                    onClickAddButton(option.inputText)
                  }}
                >
                  {addButtonProps?.text
                    ? addButtonProps.text
                    : 'Não encontrou? Clique aqui'}
                </Button>
              )}
            </Grid>
          ) : (
            <>
              {getCheckbox(option, selected)}
              {/* {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))} */}
              {rest.renderOption
                ? rest.renderOption(option, { selected, inputValue })
                : rest.getOptionLabel(option)}
            </>
          )}
        </React.Fragment>
      )
    }
  }

  function toggleOpen() {
    setAberto(!aberto)
    if (ehXS) {
      toggleAutocompleteMobile(!aberto)
    }
  }

  function handleInputChange(e, value) {
    if (value !== 'undefined') setInputTexto(value)
    else setInputTexto('')
  }

  function handleChangeValue(event, newValue: any, reason, details) {
    //Se não tiver um Id válido retorna
    var novoArray = []
    if (Array.isArray(newValue)) {
      novoArray = newValue.filter(i => !i.newValue?.btnAdd)

      //reason
      // if (
      //   reason !== 'remove-option' &&
      //   !rest.multiple &&
      //   !!newValue)
      // ) {
      //   onChange(event, novoArray, reason, details)
      //   return
      // }
    }

    if (!!newValue?.btnAdd) {
      return
    }

    if (reason === 'remove-option' && !!rest.multiple) {
      let filtrado = (rest.value as any[]).filter(
        v => v.id !== details.option.id
      )
      onChange(event, filtrado, reason, details)
      return
    } else if (!rest.multiple && !!newValue) {
      onChange(event, newValue, reason, details)
      return
    } else if (
      !rest.multiple
        ? !!newValue && !(newValue?.id || newValue?.codigo)
        : !Array.isArray(novoArray) || !!novoArray?.find(v => !v?.id)
    ) {
      return
    }

    onChange(event, newValue, reason, details)
  }

  return (
    <Box className={aberto ? `${classes.focusAutocomplete} ` : ''}>
      {aberto && ehXS ? (
        <AutocompleteMobile
          ehProcedimento={rest.ehProcedimento}
          multiple={rest.multiple}
          onChange={onChange}
          buscarPorTermo={buscarPorTermo}
          loading={rest.loading}
          value={rest.value}
          showAddButton={showAddButton}
          onClickAddButton={onClickAddButton}
          toggleOpen={toggleOpen}
          options={rest.options}
          limparTermoBusca={rest.limparTermoBusca}
          getOptionLabel={rest.getOptionLabel}
          renderOption={rest.renderOption}
        />
      ) : (
        <Box px={0}>
          <AutocompleteMU
            open={aberto}
            fullWidth
            onInputChange={(e, value, reason) => {
              handleInputChange(e, value)
            }}
            limitTags={3}
            onOpen={toggleOpen}
            onClose={toggleOpen}
            inputValue={inputTexto}
            autoHighlight
            noOptionsText="Nenhum resultado encontrado"
            disableCloseOnSelect={showCheck}
            renderInput={params => (
              <TextFieldSimpleCustom
                {...params}
                erro={erro}
                name={name}
                errorMessage={errorMessage}
                label={label}
                placeholder={label}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {rest.loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
            onChange={handleChangeValue}
            {...deveRenderizarFiltro}
            {...deveRenderizarCheck}
            {...deveRenderizarChip}
            {...rest}
            {...deveRenderizarAddButton}
          />
        </Box>
      )}
    </Box>
  )
}

export interface IAutocompleteCustomProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends IAny,
    // UseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    StandardProps<
      React.HTMLAttributes<HTMLDivElement>,
      AutocompleteClassKey,
      'defaultValue' | 'onChange' | 'children'
    > {
  options: any
  /**
   * Props applied to the [`Chip`](/api/chip/) element.
   */
  ChipProps?: object
  /**
   * The icon to display in place of the default close icon.
   */
  closeIcon?: React.ReactNode
  /**
   * Override the default text for the *clear* icon button.
   *
   * For localization purposes, you can use the provided [translations](/guides/localization/).
   */
  clearText?: string
  /**
   * Override the default text for the *close popup* icon button.
   *
   * For localization purposes, you can use the provided [translations](/guides/localization/).
   */
  closeText?: string
  /**
   * If `true`, the input will be disabled.
   */
  disabled?: boolean
  /**
   * Disable the portal behavior.
   * The children stay within it's parent DOM hierarchy.
   */
  disablePortal?: boolean
  /**
   * Force the visibility display of the popup icon.
   */
  forcePopupIcon?: true | false | 'auto'
  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth?: boolean
  /**
   * The label to display when the tags are truncated (`limitTags`).
   *
   * @param {number} more The number of truncated tags.
   * @returns {ReactNode}
   */
  getLimitTagsText?: (more: number) => React.ReactNode
  /**
   * The component used to render the listbox.
   */
  ListboxComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>
  /**
   * Props applied to the Listbox element.
   */
  ListboxProps?: object
  /**
   * If `true`, the component is in a loading state.
   */
  loading?: boolean
  /**
   * Text to display when in a loading state.
   *
   * For localization purposes, you can use the provided [translations](/guides/localization/).
   */
  loadingText?: React.ReactNode
  /**
   * The maximum number of tags that will be visible when not focused.
   * Set `-1` to disable the limit.
   */
  limitTags?: number
  /**
   * Text to display when there are no options.
   *
   * For localization purposes, you can use the provided [translations](/guides/localization/).
   */
  noOptionsText?: React.ReactNode
  /**
   * Override the default text for the *open popup* icon button.
   *
   * For localization purposes, you can use the provided [translations](/guides/localization/).
   */
  openText?: string
  /**
   * The component used to render the body of the popup.
   */
  PaperComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>
  /**
   * The component used to position the popup.
   */
  PopperComponent?: React.ComponentType<PopperProps>
  /**
   * The icon to display in place of the default popup icon.
   */
  popupIcon?: React.ReactNode
  /**
   * Render the group.
   *
   * @param {any} option The group to render.
   * @returns {ReactNode}
   */
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode
  /**
   * Render the input.
   *
   * @param {object} params
   * @returns {ReactNode}
   */
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
  /**
   * Render the option, use `getOptionLabel` by default.
   *
   * @param {T} option The option to render.
   * @param {object} state The state of the component.
   * @returns {ReactNode}
   */
  renderOption?: (
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode
  /**
   * Render the selected value.
   *
   * @param {T[]} value The `value` provided to the component.
   * @param {function} getTagProps A tag props getter.
   * @returns {ReactNode}
   */
  renderTags?: (
    value: T[] | T,
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode
  /**
   * The size of the autocomplete.
   */
  size?: 'small' | 'medium'
}
