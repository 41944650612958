import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'

const back =
  "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23260026' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23400040'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E\")"
// "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23f8f4de'/%3E%3Cstop offset='1' stop-color='%23f8f4de' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2398cfc5'/%3E%3Cstop offset='1' stop-color='%2398cfc5' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23a1d2be'/%3E%3Cstop offset='1' stop-color='%23a1d2be' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23f1f1e6'/%3E%3Cstop offset='1' stop-color='%23f1f1e6' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23fff7d6'/%3E%3Cstop offset='1' stop-color='%23fff7d6' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2300ada6'/%3E%3Cstop offset='1' stop-color='%2300ada6' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E\")"
function createSynTheme(options: ThemeOptions) {
  return createMuiTheme({
    ...options,
    typography: {
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    },
    palette: {
      primary: {
        // main: '#00859B'
        main: '#455880'
      },
      secondary: {
        // main: '#0B9981'
        main: '#1cc3a5',
        light: '#fff',
        contrastText: '#fff'
      }
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            // height: '100vh',
            margin: 0,
            padding: 0
            // backgroundImage: 'url(/background/back-2.jpg)'
          },
          body: {
            margin: 0,
            padding: 0,
            // backgroundColor: '#f6f6f6',
            backgroundColor: '#fafafa',

            '& .syn-container': {
              // padding: '50px 50px 60px ',
              // margin: '30px auto 60px',
              // padding: '0 ',
              padding: '20px',
              margin: '0 auto',
              borderRadius: '4px',
              // background: '#fff',
              // boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
              background: 'none',
              boxShadow: 'none',
              '&.no-padding': {
                padding: '0px '
              }
            },
            '& .syn-container-out': {
              width: '100%',
              minWidth: '100%',
              // height: '100vh',
              minHeight: '100vh',
              height: 'auto',
              margin: 0,
              background: '#374666',
              padding: 0,
              overflowX: 'hidden',
              overflowY: 'auto',
              paddingBottom: '40px'
            },
            '& .syn-box-out': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: '#fff',
              borderRadius: '8px',
              width: '480px',
              boxSizing: 'border-box',
              padding: '40px',
              margin: '40px auto 0'
            },
            [options.breakpoints.down('sm')]: {
              '& .syn-container-out': {
                margin: 0,
                padding: 0,
                paddingBottom: 0
              },
              '& .syn-box-out': {
                overflowX: 'hidden',
                borderRadius: '0',
                width: '100%',
                height: '100%',
                padding: '24px',
                margin: 0
              }
            },
            [options.breakpoints.down('xs')]: {
              '& .syn-container': {
                margin: '0 auto',
                padding: '20px 20px 0',
                paddingBottom: '100px',
                minHeight: '100vh',
                borderRadius: '0',
                boxShadow: 'none'
              },

              '& .no-padding': {
                padding: '0px '
              }
            }
          }
        }
      }
    }
  })
}

const synTheme = createSynTheme(createMuiTheme())
export default synTheme

// declare module '@material-ui/core/styles/createMuiTheme' {
//   interface Theme {
//     status: {
//       danger: React.CSSProperties['color']
//     }
//   }
//   interface ThemeOptions {
//     status: {
//       danger: React.CSSProperties['color']
//     }
//   }
// }

// declare module '@material-ui/core/styles/createPalette' {
//   interface Palette {
//     neutral: Palette['primary']
//   }
//   interface PaletteOptions {
//     neutral: PaletteOptions['primary']
//   }
// }
